/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'AutumnSweaterLaimon';
  src: url('fonts/Autumn/Autumn Sweater_Laimon.ttf');
}
body {
  background-color: #a3d441;
  height: 100%;
    margin: 0;
    padding: 0;
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
}

.header {
  /*background-color: #234a30;*/
  width: 100%;
  height: 6.8vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.error-message {
  color: red; /* Цвет текста ошибки */
  /* Дополнительные стили по необходимости */
}
.error-message.check-box-error {
  width: 20vw;
  margin: -1vw 0vw 0vw 0.2vw;
}
.error-message.check-box-error-second {
  width: 20vw;
  margin: 0vw 0vw 0vw 0.2vw;
}
.custom-checkbox.error {
  border: 0.07vw solid red;
}

.MuiAccordion-rounded:last-child {
  margin-bottom: 25vw;
}
.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 25vw !important;
}


.logo {
  width: 15vw;
  margin-right: auto; /* Лого будет располагаться слева */
}
.logo img {
  margin-left: 4.7vw;
  width: 156%;
}
.logo-footer img {
  margin-left: -11.3vw;
  margin-top: 1vw;
}

.title {
  font-size: 1.6vw;
  text-align: center; /* Название будет по центру */
  flex-grow: 1; /* Занимает оставшееся доступное пространство */
}



.logo,
.title,
.main-menu,
.contact-info,
.btn-leave-request {
  display: inline-block;
  vertical-align: top; /* чтобы выровнять блоки по верхнему краю */
}
.contact-info {
  padding: 2vw;
  margin-left: auto; /* Контактная информация будет располагаться справа */
}
.contact-info p {
  font-size: 1.1vw;
}
.btn-leave-request {
  font-size: 1vw;
  margin-right: 5vw;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;

}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
  pointer-events: none
}
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-photo, .svg-img {
  animation: rotate360 180s linear infinite; /* Применяем анимацию к обоим фотографиям */
}
.svg-img.faq {
  z-index: 1;
  animation: rotate360 180s linear infinite; /* Применяем анимацию к обоим фотографиям */
}
.header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 98, 65, 1);
  border-color: transparent;
  border-style: solid;
  border-radius: 0px 0px 0px 8.89vw;
  overflow: hidden;
  margin-left: auto;
  position: relative; /* Изменение позиционирования на relative */
  z-index: 10;
}
.left-photo {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 13.62vw;
  height: auto;
  margin-top: -2.22vw;
  margin-left: -5.31vw;
  z-index: 2;
}
.svg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 91.32vw;
  height: 91.32vw;
  margin-top: -41.5vw;
  margin-left: -44.15vw;
  z-index: 1;
}

.logo {
  padding: 0.52vw;
}

.logo img {
  /*max-width: 5.21vw; !* максимальная ширина логотипа *!*/
}

.menu {
  display: flex; /* меню в один ряд */
}

.main-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex; /* меню в один ряд */
}
.bottle-float-left.example-tree5-5 {
  position: absolute;
  margin-top: -9.95vw;
  margin-left: 24.33vw;
  width: 6.53vw;
  height: auto;
  z-index: 4;
  animation: sbs-231425214 15s infinite linear;
  backface-visibility: hidden;
}

.main-menu li {
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  line-height: 1.3vw;
  font-size: 1.11vw;
  font-weight: 400;
  margin-right: -3.9vw;
  margin-top: 0.66vw;
  padding: 0.5vw 1vw;
  color: #fff;
  letter-spacing: 0.2vw;
}
.main-menu .li-rules {
  margin-right: 1.1vw;
}
.main-menu .li-ont{
  margin-right: -1vw;
}
.main-menu .active-block {
  color: rgba(245, 199, 78, 1);
}

.main-menu .li-lc{
  margin-left: 1.6vw;
}
.shadows-li.shadows-li {
  display: none;
}
.main-menu .li-lc:hover {
  cursor: pointer;
}
.shadow-dont {
  display: none;
}
.main-menu {
  margin-left: 3vw;
}
.main-menu a {
  color: white;
  text-decoration: none;
  transition: color 0.3s, text-decoration 0.3s; /* Add smooth transition */
}

.main-menu a:hover {
  color: rgba(245, 199, 78, 1);
}
.main-items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.main-promo {
  position: absolute;
  margin: 5.5vw 0vw 0vw 10.7vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 3.000vw;
  color: rgba(227, 29, 26, 1);
  z-index: 5;
}
.main-promo.block {
  border-radius: 0.903vw;
  border: 1px;
  background: rgba(255, 255, 255, 0.31);
  color: rgba(0, 98, 65, 1);
  width: 24.722vw;
  height: 7.964vw;
  font-size: 1.250vw;
  border-color: rgba(0, 98, 65, 1);
  border-style: solid;
  line-height: 1.400vw;
  padding-top: 1vw;
  text-transform: none;
  text-align: center;
  margin: 2vw 0vw 0vw 10vw;
  font-family: Inter, sans-serif;
}
.main-promo-text-input {
  position: absolute;
  margin: -3.43vw 0vw 0vw 2.6vw;
  text-transform: none;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 1.736vw;
  line-height: 1.667vw;
  font-weight: 600;
  z-index: 10;
}
.main-promo-text-input-new-mobile {
  position: absolute;
  margin: -3.43vw 0vw 0vw 4.6vw;
  text-transform: none;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 1.736vw;
  line-height: 1.667vw;
  font-weight: 600;
  z-index: 10;
}

.Modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #FFFFFF4D;
  width: 33.28vw;
  height: 25.49vw;
  padding: 1.46vw;
  border-radius: 1.37vw;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.textarea-sms {
  max-width: 33vw;
  min-width: 15vw;
  min-height: 10vw;
}
.Modal::-webkit-scrollbar {
  width: 0.5vw; /* Ширина скроллбара */
}
.Modal .popup-h1 {
  margin-left: 3vw;
}
.Modal::-webkit-scrollbar-thumb {
  background-color: transparent; /* Цвет ползунка скроллбара */
}

.Modal::-webkit-scrollbar-track {
  background-color: transparent; /* Цвет трека скроллбара */
}

.content-container-modal {
  margin-left: 5vw;
}
.popup-content-wrapper {
}


.Modal input,
.Modal textarea,
.Modal button {
  margin-bottom: 1vw; /* Добавляет отступ между элементами */
}




.Overlay {
  /*backdrop-filter: blur(10px); !* Размытие заднего фона *!*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}
.ReactModal__Overlay--after-open:focus {
  outline: none;
}


.content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Чтобы разместить по центру по вертикали */
  height: auto;
  padding-bottom: 5vw;
  width: 95vw;
}
.content-container-footer {
  background-color: rgba(0, 98, 65, 1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Чтобы разместить по центру по вертикали */
  height: auto;
}
.main {
  background-color: rgba(162, 212, 65, 1);
  background-size: cover;
  width: 100%;
  height: 46.95vw;
  font-family: 'Circe', sans-serif;
  font-weight: 700;
  scroll-behavior: smooth;
  font-size: 0.83vw;
  line-height: 1.5;
}
#wave-container-test {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: rgba(248, 214, 72, 1);
}

#wave-test {
  display: block;
  position: relative;
  height: 2.08vw;
  background: #a3d441;
}

#wave-test:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(248, 214, 72, 1);
  right: -25%;
  top: 1.04vw;
}
.bottle-float-left.example-tree5.main-down-town {
  display: none;
}

#wave-test:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: #a3d441;
  left: -25%;
  top: -12.50vw;
}


#wave-container-test-2 {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: rgba(91, 171, 113, 1);
}

#wave-test-2 {
  display: block;
  position: relative;
  height: 2.08vw;
  background: rgba(248, 214, 72, 1);
}
.scroll-container-winners-block {
  position: relative;
  overflow: hidden;
  width: 76vw;
  height: 18vw;
}
.block-winners-wrapper {
  position: relative;
  /* overflow: hidden; */
  width: 76vw;
  height: 27.25vw;
}
.content-right-u {
  position: absolute;
  margin: -30.85vw 0vw 0vw 26vw;
  background-color: #FFC828;
  border-bottom-right-radius: 2.08vw;
  border-top-right-radius: 2.08vw;
  flex: 1 1;
  display: flex;
  width: 26vw;
  height: 32.48vw;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.lfur-r {
  position: absolute;
  margin: -6vw 0vw 0vw -49vw;
  width: 81vw;
  height: auto;
  opacity: 0.7;
}
.lfur-r-linef {
  position: absolute;
  margin: -30.91vw 0vw 0vw 25.7vw;
  z-index: 1;
  width: 1.9864vw;
  height: auto;
}
.lfur-r-btl {
  margin: 1vw 0vw 0vw -4.3vw;
  width: 25vw;
  z-index: 3;
  height: auto;
}
.lfur-r-tct {
  position: absolute;
  margin: -21vw 0vw 0vw 22vw;
  z-index: 2;
  width: 15vw;
  height: auto;
}
#wave-test-2:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(91, 171, 113, 1);
  right: -25%;
  top: 1.04vw;
}

#wave-test-2:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(248, 214, 72, 1);
  left: -25%;
  top: -12.50vw;
}
#wave-container-test-22 {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: rgba(91, 171, 113, 1);
}

#wave-test-22 {
  display: block;
  position: relative;
  height: 2.08vw;
  background: #a3d441;
}

#wave-test-22:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(91, 171, 113, 1);
  right: -25%;
  top: 1.04vw;
}

#wave-test-22:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: #a3d441;
  left: -25%;
  top: -12.50vw;
}
.jss2 {
  width: 87%;
  margin-left: 7vw !important;
}
.jss3 {
  color: #286043 !important;
  font-size: 0.9375rem !important;
  flex-basis: 100.33% !important;
  flex-shrink: 0 !important;
}
#wave-container-test-3 {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: #a3d441;
}

#wave-test-3 {
  display: block;
  position: relative;
  height: 2.08vw;
  background: rgba(91, 171, 113, 1);
}

#wave-test-3:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: #a3d441;
  right: -25%;
  top: 1.04vw;
}
.styledsd {
  width: 36vw !important;
  height: 14vw !important;
}
.bottle-float-left.example-tree5.faq-down-leetree {
 display: none;
}

#wave-test-3:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(91, 171, 113, 1);
  left: -25%;
  top: -12.50vw;
}
#wave-container-test-4 {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: rgba(0, 98, 65, 1);
}
#wave-test-4 {
  display: block;
  position: relative;
  height: 2.08vw;
  background: #a3d441;
}
#wave-test-4:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(0, 98, 65, 1);
  right: -25%;
  top: 1.04vw;
}
#wave-test-4:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: #a3d441;
  left: -25%;
  top: -12.50vw;
}
#wave-container-test-44 {
  width: 100%;
  height: 5.21vw;
  overflow: hidden;
  background: rgba(0, 98, 65, 1)
}
#wave-test-44 {
  display: block;
  position: relative;
  height: 2.08vw;
  background: rgba(91, 171, 113, 1);
}
#wave-test-44:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(0, 98, 65, 1);
  right: -25%;
  top: 1.04vw;
}
.main-promo-block-test {
  display: none;
}
#wave-test-44:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 15.63vw;
  background: rgba(91, 171, 113, 1);
  left: -25%;
  top: -12.50vw;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes fade-in-out {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
.copied-notification {
  color: rgba(0, 98, 65, 1);
  font-family: Inter;
  font-weight: 400;
  position: absolute;
  margin: -20.7vw 0vw 0vw 39.5vw;
  animation: fade-in-out 1.5s ease-out;
}

.copied-notification--hidden {
  animation: fade-out 0.3s ease forwards;
}

/*#wave-test:after {*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  border-radius: 100%;*/
/*  width: 100%;*/
/*  height: 300px;*/
/*  background-color: #a3d441;*/
/*  left: -26%;*/
/*  top: -256px;*/
/*  margin-top: 0vw;*/
/*}*/
.bottle-left {
  flex: 1;
  padding: 0 0 0 0;
}

.bottle-float-left {
  position: relative;
  margin-top: 2.35vw;
  margin-left: 1.83vw;
  width: 90.5%;
  height: 100%;
  z-index: 5;
}
.bottle-float-left-mobile {
  display: none;
}
.mega-test {
  position: relative;
  z-index: 5;
}
.bottle-float-left.twotree {
  position: absolute;
  margin-top: -14.65vw;
  margin-left: 33.83vw;
  width: 14.5%;
  height: 3%;
  z-index: 5;
  right: 0;
}
.bottle-float-left.exit-lc {
  margin: -4vw 0vw 0vw 35vw;
  position: absolute;
  width: 3.40vw;
  height: 3.40vw;
  z-index: 5;
  transition: opacity 0.3s ease; /* добавляем плавную анимацию для свойства opacity */
}
.bottle-float-left.exit-lc:hover {
  cursor: pointer;
  opacity: 0.6;
}
.exit-lc-text {
  position: absolute;
  margin: 0 0 0 16.75vw;
  position: absolute;
  z-index: 5;
  font-size: 1.39vw;
  line-height: 1.67vw;
  font-weight: 400;
  text-transform: none;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
}
.bottle-float-left.exit-register {
  right: 0;
  padding-right: 0.7vw;
  margin: -4.2vw 0vw 0vw 35vw;
  position: absolute;
  width: 3.40vw;
  height: 3.40vw;
  z-index: 5;
  transition: opacity 0.3s ease;
}
.bottle-float-left.exit-register-u {
  right: 0;
  padding-right: 0.7vw;
  margin: -6.2vw 0vw 0vw 35vw;
  position: absolute;
  width: 3.40vw;
  height: 3.40vw;
  z-index: 5;
  transition: opacity 0.3s ease;
}

.bottle-float-left.u {
  padding-right: 0.7vw;
  margin: -1.2vw 0vw 0vw 0vw;
  position: absolute;
  width: 5.4vw;
  height: auto;
  z-index: 5;
  transition: opacity 0.3s ease;
}
.bottle-float-left.success-email {
  margin: -12.84vw 0vw 0vw 32vw;
}
.bottle-float-left.success-email-new {
  margin: -0.84vw 0vw 0vw 32vw;
}
.bottle-float-left.success-image {
  padding-right: 0.7vw;
  margin: -10.5vw 0vw 0vw -4.5vw;
  position: absolute;
  width: 8.81vw;
  height: auto;
  z-index: 5;
  transition: opacity 0.3s ease;
}
.bottle-float-left.success-image-new {
  padding-right: 0.7vw;
  margin: 1.5vw 0vw 0vw -4.5vw;
  position: absolute;
  width: 8.81vw;
  height: auto;
  z-index: 5;
  transition: opacity 0.3s ease;
}
.error {
  width: 100%;
  height: auto;
  z-index: 5;
  position: relative;
}
.error-clear {
  text-decoration: none;
  color: white;
}
.error-text {
  color: rgba(0, 98, 65, 1);
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 6.32vw;
  font-weight: 400;
  line-height: 6.04vw;
}
.error-text.first {
  margin-top: 4vw;
  margin-bottom: -1vw;
}
.error-text.second{

  font-size: 26.67vw;
  font-weight: 400;
  line-height: 25.56vw;
}
.error-text.third{
  width: 55.49vw;
  height: 10vw;
  font-family: Inter;
  text-align: center;
  font-size: 1.39vw;
  font-weight: 400;
  line-height: 1.67vw;
  margin: 0 auto;
}
.bottle-float-left.fail-image {
  padding-right: 0.7vw;
  margin: 6.8vw 0vw 2vw 15vw;
  position: absolute;
  width: 8.26vw;
  height: 8.2vw;
  z-index: 5;
  transition: opacity 0.3s ease;
}
.register-main-text {
  display: flex; /* Используем Flexbox */
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100%; /* Для центрирования по вертикали */
  color: rgba(163, 212, 65, 1);
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 2.78vw;
  font-weight: 400;
  line-height: 3.61vw;
}
.register-main-text.u {
  width: 25vw;
  display: flex; /* Используем Flexbox */
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100%; /* Для центрирования по вертикали */
  color: #FFFFFF;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 2.78vw;
  font-weight: 400;
  line-height: 3vw;
}
.register-main-text.soon {
  width: 34vw;
  margin: 4vw 0vw 0vw 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgba(163, 212, 65, 1);
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 2.78vw;
  font-weight: 400;
  line-height: 3.61vw;
}
.register-main-text.soon-white {
  width: 35vw;
  margin: 2vw 0vw 0vw 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgba(255, 255, 255, 1);
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 6.52vw;
  font-weight: 400;
  line-height: 3.61vw;
}
.register-main-text.top-margin {
  margin-top: 12.5vw;
}
.register-main-text.new-meow {
  text-align: center;
  margin-bottom: 4vw;
}
.register-main-text.new-meow-second {
  text-align: center;
  margin-bottom: 5.4vw;
}
.register-main-text.new-meow-1 {
  position: absolute;
  text-align: left;
  margin: -2.6vw 15vw 0vw -2.4vw;
}
.register-main-text.new-meow-2 {
  text-align: left;
  position: absolute;
  margin: -4.6vw 15vw 0vw -2.4vw;
}
.register-main-text.fail-red {
  margin-top: 16vw;
  text-align: center;
  color: rgba(227, 29, 26, 1);
}
.simplebar-scrollbar.simplebar-visible {
}
.register-inputs-mobile {
  display: none;
}
.register-inputs-text {
  text-align: left;
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 1.18vw;
  line-height: 1.46vw;
  margin-bottom: 0.5vw;
  margin-left: 1vw;
}
.error-block-phone {
  position: absolute;
  color: #ffaeae !important;
  margin: 0.1vw 1vw 0vw 1vw;
  font-size: 1vw;
}
.error-block-phone.test-register {
  position: absolute;
  color: #ffaeae !important;
  margin: -0.7vw 1vw 0vw 1vw;
  font-size: 1vw;
}
.error-block-phone.test-code-add {
  position: absolute;
  color: #ffaeae !important;
  margin: 1.2vw 1vw 0vw 1vw;
  font-size: 1vw;
}
.error-block-phone.code-bottom {
  position: absolute;
  color: #ffaeae !important;
  margin: -0.7vw 1vw 0vw 1vw;
  font-size: 1vw;
}
.register-inputs-text.login {
  width: 19.5vw;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 1.18vw;
  line-height: 1.46vw;
  margin-top: 2vw;
  margin-bottom: -1vw;
  margin-left: 8.5vw;

}
.register-inputs-text.code-left {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 3.5vw;
  margin-left: 5vw;
  width: 29vw;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 1.18vw;
  line-height: 1.46vw;
  margin-top: 3vw;
  margin-bottom: 2.5vw;
  margin-left: 4vw;
}
.register-inputs-text.u {
  width: 20vw;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  display: flex; /* Используем Flexbox */
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100%; /* Для центрирования по вертикали */
  margin-bottom: 3.5vw;
  margin-left: 0vw;
  font-weight: 600;
}
.link-here-mobile {
  display: none;
}
.link-here {
  background-image: linear-gradient(to right, #FFC828, #E31D1A, #E31D1A);
  margin: -6.7vw 0vw 0vw 16.7vw;
  padding: 0.2vw 0.8vw 0.2vw 0.8vw;
  border-radius: 2vw;
  position: absolute;
  cursor: pointer;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.18vw;
  line-height: 1.46vw;
  text-decoration: none;
  color: #FFFFFF;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.link-here:hover {
  opacity: 0.8;
}
.register-inputs-text.u2 {
  font-weight: 600;
  margin-top: -1vw;
  width: 23vw;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  display: flex; /* Используем Flexbox */
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100%; /* Для центрирования по вертикали */
  margin-bottom: 3.5vw;
  margin-left: -0.8vw;
}
.register-inputs-text.new-password {
  color: rgba(163, 212, 65, 1);
  display: flex; /* Используем Flexbox */
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100%; /* Для центрирования по вертикали */
  margin-bottom: 2.5vw;
  margin-left: 0vw;
}
.register-inputs-text.new-reg {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  margin-bottom: 2.5vw;
  margin-top: -3vw;
  margin-left: 0vw;
}
.register-inputs-text.new-reg-number {
  width: 23vw;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  margin-bottom: 4.5vw;
  margin-top: -3vw;
  margin-left: 8vw;
}

.bottle-float-left.exit-register:hover {
  cursor: pointer;
  opacity: 0.6;
}
.bottle-float-left.exit-register-u:hover {
  cursor: pointer;
  opacity: 0.6;
}
.bigline-container {
  flex: 0;
  padding: 0 0 0 0;
  position: relative;
}
.bigline-container-mobile {
  display: none;
}

.bottle-float-left.two {
  position: absolute;
  margin-top: 23.75vw;
  margin-left: -47.17vw;
  width: 51.5%;
  height: auto;
  z-index: 3;
  right: 0;
}
.bottle-float-left.air {
  position: absolute;
  margin-top: 4.75vw;
  margin-left: -36.17vw;
  width: 10.642vw;
  height: auto;
  z-index: 4;
}
.bottle-float-left.air-bonus {
  position: absolute;
  margin-top: 12.65vw;
  margin-left: -43.17vw;
  width: 29.659vw;
  height: auto;
  z-index: 4;
}
.full-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.black-b {
  color: white;
}
.black-b.black {
  color: black;
}
.margin-left-17 {
  margin-left: 2vw;
}

.full-image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Apply a blur effect for modern browsers */
  -webkit-backdrop-filter: blur(5px); /* Add support for older versions of Safari */
}
.no-scroll {
  overflow: hidden !important; /* Запретить прокрутку */
}

.full-image {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 1.4vw;
  transform: translate(5%, -50%) scale(1); /* Уменьшить изображение на 20% */
  object-fit: cover;
  object-position: 67% center; /* Смещение изображения внутри контейнера вправо */
  width: 25vw; /* Ширина по вашему выбору */
  height: 33.33vw; /* Высота также сделана равной ширине, чтобы сделать изображение квадратным */
}
.bottle-float-left.berry {
  position: absolute;
  margin-top: 18.55vw;
  margin-left: -23.67vw;
  width: 8.06vw;
  height: 8.26vw;
  z-index: 4;
  animation: sbs-231425214-52151 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.treepape-footer {
  position: absolute;
  margin-top: -9.45vw;
  margin-left: 48.5vw;
  width: 8.47vw;
  height: 10vw;
  z-index: 4;
}
.bottle-float-left.example-tree5 {
  position: absolute;
  margin-top: -8.95vw;
  margin-left: 27.33vw;
  width: 6.53vw;
  height: 7.08vw;
  z-index: 4;
  animation: sbs-231425214-52151 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.example-tree5.winners {
  width: 6vw;
  margin-top: -13.95vw;
  margin-left: 26.33vw;
  transform: rotate(-104deg);
  height: auto;
  animation: sbs-231425214-52151 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.example-tree5.profile {
  margin-top: 25.05vw;
  margin-left: 85.13vw;
  width: 8.19vw;
  height: 8.89vw;
}
.bottle-float-left.example-tree5.profile-down {
  z-index: 0;
  margin-top: -8.95vw;
  margin-left: -42.87vw;
  width: 12.01vw;
  height: 14.31vw;

}
.bottle-float-left.example-tree5.faq-down {
  z-index: 4;
  margin-top: -15.95vw;
  margin-left: -42.87vw;
  width: 17.01vw;
  height: auto;
}
.bottle-float-left.example-tree5.faq-down-down {
  z-index: 4;
  margin-top: -15.95vw;
  margin-left: -42.87vw;
  width: 17.01vw;
  height: auto;
}
.bottle-float-left.example-tree5.main-down {
z-index: 4;
margin-top: -40.95vw;
margin-left: -47.87vw;
width: 21.01vw;
height: auto;
}
.bottle-float-left.lime-winners {
  position: absolute;
  margin-top: -38.17vw;
  margin-left: -2.97vw;
  right: 0;
  width: 8vw;
  height: 17vw;
  z-index: 4;
}
.bottle-float-left.fruit {
  position: absolute;
  margin-top: -42.95vw;
  margin-left: -48.97vw;
  width: auto;
  height: 22.08vw;
  z-index: 4;
  /* animation: sbs-231425214-52151 15s infinite linear; */
  backface-visibility: hidden;
  transform: rotate(12deg);
}
.bottle-float-left.example-tree6 {
  position: absolute;
  margin-top: 18.05vw;
  margin-left: -48.67vw;
  width: 3.47vw;
  height: 3.82vw;
  z-index: 4;
  animation: sbs-231425214 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.example-tree6.winners {
  position: absolute;
  margin-top: 27.05vw;
  margin-left: -46.67vw;
  width: 8vw;
  height: auto;
  z-index: 4;
  animation: sbs-231425214 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.berry-prizes {
  position: absolute;
  margin-top: 2.95vw;
  margin-left: 41.93vw;
  width: 8.06vw;
  height: 8.26vw;
  transform: scaleX(-1);
  animation: sbs-23142-left 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.berry-profile {
  position: absolute;
  margin-top: 2.25vw;
  margin-left: 37.93vw;
  width: 8.06vw;
  height: 8.26vw;
  transform: scaleX(-1);
  animation: sbs-23142-left 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.berry-winners {
  position: absolute;
  margin-top: -38.75vw;
  margin-left: 16.93vw;
  width: 8.06vw;
  height: 8.26vw;
  transform: scaleX(-1);
  animation: sbs-23142-left 15s infinite linear;
  backface-visibility: hidden;
}
.error-message {
  position: absolute;
  color: #ffaeae;
  margin: -0.5vw 1vw 0vw 1vw;
  font-size: 1vw;
}
.error-message.password-one {
  position: absolute;
  color: #ffaeae;
  margin: 0.5vw 1vw 0vw 2vw;
  font-size: 1vw;
}
.bottle-float-left.inputcode {
  position: absolute;
  margin-top: 3.1vw;
  margin-left: -46.57vw;
  width: 21.7vw;
  height: 5vw;
  z-index: 6;

}
.winners-text-inputcode {
  margin: -2.6vw 0vw 0vw 6.35vw;
}
.winners-text-inputcode.lc {
  margin: -1.48vw 0vw 0vw 6.35vw;
}
.text-inputcode {
  margin: 0vw 0vw 0vw 0vw;
  font-family: Inter;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.18vw;
  text-transform: none;
}
.text-inputcode.cards-prize {
  font-size: 3.47vw;
  margin-top: 1vw;
  margin-bottom: 5vw;font-family: AutumnSweaterLaimon;
}
.text-block-inputcode {
  display: block;
  margin: 31vw 0vw 0vw -44vw;
}
.text-inputcode.lc {
  color:rgba(0, 98, 65, 1);
  margin: 0vw 0vw 0vw 0vw;
  font-family: Inter;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.18vw;
  text-transform: none;


}
.bottle-float-left.uptree {
  position: absolute;
  margin-top: 12.63vw;
  margin-left: -7.57vw;
  width: 4.3%;
  height: 1.38%;
  z-index: 4;
  animation: sbs-23142 15s infinite linear; /* Применяем анимацию petal-animation на протяжении 5 секунд бесконечно */
  backface-visibility: hidden;
}
.bottle-float-left.uptree-prod {
  position: absolute;
  margin-top: -1.9vw;
  margin-left: -29.07vw;
  width: 4.6%;
  height: 1.9%;
  z-index: 4;
  animation: sbs-231425214-52151 15s infinite linear; /* Применяем анимацию petal-animation на протяжении 5 секунд бесконечно */
  backface-visibility: hidden;
}
.bottle-float-left.gps-top-product {
  position: absolute;
  margin-top: -1.3vw;
  margin-left: -11.17vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bootle-float-left-mobile-gps {
  display: none;
}
.bottle-float-left.nv {
  display: none;
}
.bottle-float-left.gps-down-product {
  position: absolute;
  margin-top: 41.7vw;
  margin-left: -80.87vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.top-tree-prod{
  position: absolute;
  margin-top: 7.5vw;
  margin-left: -64vw;
  width: 5.6%;
  height: 1.9%;
  z-index: 4;
  animation: sbs-231425214 15s infinite linear; /* Применяем анимацию petal-animation на протяжении 5 секунд бесконечно */
  backface-visibility: hidden;
}
.bottle-float-left.top-tree-how {
  position: absolute;
  margin-top: 14.5vw;
  margin-left: 30vw;
  width: 5.6%;
  height: 1.4%;
  z-index: 4;
  animation: sbs-231425214 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.left-line-how {
  position: absolute;
  margin-top: -71.5vw;
  margin-left: -59vw;
  width: 97vw;
  height: 198vw;
  z-index: 1;
  pointer-events: none;
}
.bottle-float-left.down-line-winners {
  position: absolute;
  margin-top: 11.1vw;
  margin-left: -33vw;
  width: 54.10vw;
  height: 19.31vw;
  z-index: 1;
  transform: rotate(-1deg);
}
.bottle-float-left.down-tree-prod {
  position: absolute;
  margin-top: 38.973vw;
  margin-left: -47.97vw;
  width: 7.3%;
  height: 1.968%;
  z-index: 4;
  animation: sbs-23142 15s infinite linear; /* Применяем анимацию petal-animation на протяжении 5 секунд бесконечно */
  backface-visibility: hidden;
}
.bottle-float-left.nature {
  position: absolute;
  margin-top: 7.53vw;
  margin-left: -11.97vw;
  width: 7.9%;
  height: 2.1%;
  z-index: 4;
  animation: sbs-23142-552431 2s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.lime {
  position: absolute;
  margin-top: 38.53vw;
  margin-left: -0.97vw;
  right: 0;
  width: 13.60vw;
  height: 15.60vw;
  z-index: 4;
}
.bottle-float-left.lime-profile {
  position: absolute;
  margin-top: -8.47vw;
  margin-left: 0.03vw;
  right: 0;
  width: 13.60vw;
  height: 15.60vw;
  z-index: 0;
}
.bottle-float-left.lime-faq {
  position: absolute;
  margin-top: -25.47vw;
  margin-left: -2.97vw;
  right: 0;
  width: 8vw;
  height: 17vw;
  z-index: 4;
}
.bottle-float-left.gps {
  position: absolute;
  margin-top: 39.13vw;
  margin-left: -86.47vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.gps-sochi {
  position: absolute;
  margin-top: 19.73vw;
  margin-left: -40.97vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.gps-kalin {
  position: absolute;
  margin-top: 2.73vw;
  margin-left: -37.97vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.gps-eka {
  position: absolute;
  margin-top: 10.99vw;
  margin-left: 25.67vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.gps-novo {
  position: absolute;
  margin-top: 30.73vw;
  margin-left: 38.03vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left.gps-vlad {
  height: 4.24vw;
  margin-left: -20.2vw;
  margin-top: 32.93vw;
  position: absolute;
  width: 3.47vw;
  z-index: 4;
}
.bottle-float-left.gps-chel {
  position: absolute;
  margin-top: 21.83vw;
  margin-left: 26.03vw;
  width: 3.47vw;
  height: 4.24vw;
  z-index: 4;
}
.bottle-float-left-mobile-gps {
  display: none;
}
.bottle-float-left.show {
  display: none;
}
.bottle-float-left.lefttree {
  position: absolute;
  margin-top: 45.6vw;
  margin-left: -88.47vw;
  width: 12%;
  height: 3.4%;
  z-index: 4;
  animation: sbs-23142 15s infinite linear;
  backface-visibility: hidden;
}
.bottle-float-left.lefttree-mobile {
  display: none;
}
.bottle-float-left.lefttree-how {
  position: absolute;
  margin-top: -5.4vw;
  margin-left: -48.47vw;
  width: 12%;
  height: 3.4%;
  z-index: 4;
  animation: sbs-23142 15s infinite linear;
  backface-visibility: hidden;
}
@keyframes sbs-23142 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
    animation-timing-function: linear;
  }
  33% {
    transform: translateX(32px) translateY(38px) rotate(15deg);
    animation-timing-function: linear;
  }
  66% {
    transform: translateX(-36px) translateY(30px) rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
}
@keyframes sbs-23142-left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
    animation-timing-function: linear;
  }
  33% {
    transform: translateX(-32px) translateY(38px) rotate(15deg);
    animation-timing-function: linear;
  }
  66% {
    transform: translateX(-15px) translateY(15px) rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
}
@keyframes sbs-231425214 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
    animation-timing-function: linear;
  }
  33% {
    transform: translateX(-14px) translateY(16px) rotate(13deg);
    animation-timing-function: linear;
  }
  66% {
    transform: translateX(24px) translateY(18px) rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
}
@keyframes sbs-231425214-52151 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
    animation-timing-function: linear;
  }
  33% {
    transform: translateX(14px) translateY(16px) rotate(13deg);
    animation-timing-function: linear;
  }
  66% {
    transform: translateX(-4px) translateY(18px) rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
}
@keyframes sbs-23142-552431 {
  0% {
    transform: scale(1, 1);
    animation-timing-function: linear;
  }
  50% {
    transform: scale(1.05, 1.05);
    animation-timing-function: linear;
  }
  100% {
    transform: scale(1, 1);
  }
}

.bottle-float-left.bigline {
  pointer-events: none;
  position: absolute;
  margin-top: 44.73vw;
  margin-left: 66.03vw;
  width: 97vw;
  height: 226vw;
  z-index: 1;
  right: 0;
}
.bottle-float-left.bigline-mobile {
  display: none;
}
.container-absolute {
  position: relative; /* Устанавливаем родительский контейнер как position: relative; */
  width: 100%;
  height: 100vh;
}
.shadow-button-animation-text {
  display: none;
}
.blur-filter-test {
  backdrop-filter: blur(10px); /* Размытие заднего фона */
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.blur-filter-test.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.absolute-text {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 44vw 21vw 5vw 10vw;
}
.absolute-text-mobile {
  display: none;
}
.absolute-text.sochi {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 8vw 21vw 5vw 10vw;
}
.absolute-text.kalin {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 8.2vw 22vw 7vw -9.4vw;
}
.absolute-text.eka {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 15.2vw 21vw 5vw 53.6vw;
}
.absolute-text.novo {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 36.4vw 23vw 5vw 65.6vw;
  z-index: 1;
}
.absolute-text.vlad {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 37.8vw 21vw 5vw 7.6vw;
  z-index: 1;
}
.absolute-text.nv {
  width: 25vw;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 0.5vw 21vw 5vw 61vw;
}
.absolute-text.chel {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 10.2vw 21vw 5vw 74.4vw;
}
.absolute-text.prod {
  color: rgba(0, 98, 65, 0.5);
  width: 20vw;
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: 3.8vw 6vw 4vw 78vw;
}
.absolute-text.how {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  /* width: 20vw; */
  font-family: "Inter";
  font-size: 1.74vw;
  font-weight: 700;
  line-height: 2.08vw;
  position: absolute;
  margin: -4.055vw 6vw 4vw 15.3vw;
}
.accordion-example {
  position: relative;
  z-index: 0;
  padding-top: 7vw;
  padding-bottom: 0vw;
  /*overflow: hidden;*/
  justify-content: center;
  align-items: center;
  height: auto; /* Высота равная высоте видимой области экрана */
  background-color: #a3d441;
  background-size: cover;
  width: 100%;
  font-size: 0.83vw;
}
.accordion-example.faq {
  padding-top: 0vw;
  position: relative;
  z-index: 1;
  padding-bottom: 0vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: auto;
   background-color: inherit;
  width: 100%;
  font-size: 0.83vw;
}
.accordion-example.lc {
  padding-top: 0vw;
  position: relative;
  z-index: 1;
  padding-bottom: 0vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: inherit;
  width: 100%;
  font-size: 0.83vw;
}
.faq-question {
  margin-left: 1vw;
  /*text-decoration: underline;*/
  color: rgba(0, 98, 65, 1);
  font-family: Inter;
  font-weight: 600;
  font-size: 1.74vw;
  line-height: 1.67vw;
  margin-bottom: 21vw;
}
.faq-question-p {
  margin-top: 5vw;
  margin-bottom: 2vw;
  font-size: 1.39vw;
  line-height: 1.67vw;
  font-family: Inter;
  font-weight: 400;

}
.faq-question a:hover{
}
.faq-question-p-a {
  cursor: pointer;
  font-size: 1.74vw;
  line-height: 2.08vw;
  font-family: Inter;
  font-weight: 400;
  text-decoration: underline;
}
.MuiAccordion-root:first-child {
  background-color: white; /* Цвет фона для закрытого аккордеона */
  transition: background-color 0.3s; /* Плавное изменение цвета фона */
  border-top-left-radius: 2.88vw !important;
  border-top-right-radius: 2.88vw !important;
  border-bottom-right-radius: 2.88vw !important;
  border-bottom-left-radius: 2.88vw !important;
}
.MuiAccordion-root.Mui-expanded:first-child {
  z-index: 5;
  background-color: rgba(0, 98, 65, 1); /* Цвет фона для раскрытого аккордеона */
}
.MuiAccordion-rounded {
  margin-bottom: 1vw;
  color: white !important;
  background-color: white; /* Цвет фона для закрытого аккордеона */
  transition: background-color 0.3s !important; /* Плавное изменение цвета фона */
  border: 2px solid #286043 !important;
  border-radius: 2.8vw !important;

}
.MuiAccordion-root:before {
  display: none;
}

.MuiAccordion-root.Mui-expanded {
  background-color: rgba(0, 98, 65, 1); /* Цвет фона для раскрытого аккордеона */
}

.makeStyles-heading-3.Mui-expanded {
  flex-basis: 70.33% !important;
  color: white;
}
.MuiAccordion-root.Mui-expanded .makeStyles-heading-3 {
  color: white; /* Изменение цвета текста при открытии аккордеона */
}
.MuiAccordion-root.Mui-expanded .jss3 {
  color: white !important; /* Изменение цвета текста при открытии аккордеона */
}
.jss5 {
  width: 77vw !important;
  margin: 0px 0px 25px 1px !important;
  border-top: 2px solid #ccc;
  background-color: #fff;
}
.makeStyles-heading-3 {
  flex-basis: 100.33% !important;
  color: #286043; /* Цвет текста по умолчанию */
  transition: color 0.3s ease; /* Добавляем анимацию перехода для цвета текста */
}
.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-content {
  color: black !important;
}

.makeStyles-heading-3.Mui-expanded {
  flex-basis: 70.33% !important;
}

.MuiAccordion-root.Mui-expanded .heading {
  color: white !important; /* Изменение цвета текста заголовка при открытии */
}
.slick-slide {
  margin-right: 0vw;
}

.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-content {
  margin: 1.04vw 0; /* Установка отступа для контента при открытии */
}
.makeStyles-root-2 {
  z-index: 5 !important;
  width: 86% !important;
  margin-left: 7vw;
}
.centered-container {
  z-index: 5 !important;
  width: 86% !important;
}
.centered-container.jss2 {
  z-index: 5 !important;
  width: 86% !important;
}
.accordion__item {
  flex-direction: row-reverse; /* Открывать элементы аккордеона справа */
}
/*.slick-slide img {*/
/*  display: flex !important;*/
/*}*/
/*.slick-next:before {*/
/*  font-size: 5vw !important;*/
/*  margin-left: -0.8vw;*/
/*  color: gray !important;*/
/*}*/
/*.slick-prev:before {*/
/*  font-size: 5vw !important;*/
/*  color: gray !important;*/
/*  margin-left: -1.8vw;*/
/*}*/
/*.slider {*/
/*  background-color: white;*/
/*  max-width: 135%; !* Максимальная ширина слайдера *!*/
/*  overflow: unset;*/
/*  !*text-align: center; !* Центрирование элементов внутри слайдера *!*!*/
/*}*/
/*.slick-track {*/
/*  overflow: auto;*/
/*  margin-left: 4vw !important;*/
/*}*/
.slick-next {
  z-index: 5;
  right: -3.5vw !important;

}
/*.slick-prev {*/
/*  z-index: 5;*/
/*  left: 45vw !important;*/
/*}*/
/*.slider img {*/
/*  margin-left: 0.1px;*/
/*  max-width: 34vw;*/
/*  height: 25vw;*/
/*}*/
/*.slick-slide.slick-active{*/
/*  opacity: 0.5; !* Изначально делаем центральную фотографию полностью непрозрачной *!*/
/*  transition: opacity 0.5s ease;*/
/*}*/
.slider-container {
  z-index: 5;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  position: relative;
  max-width: 80.88vw;
  margin: 0 auto;
  text-align: center;
  margin-top: -4.7vw;
  margin-left: 5.7vw;
}

.slick-prev {
  color: white !important;
  left: 1.5vw !important;
  z-index: 1 !important; /* Устанавливаем z-index, чтобы стрелка была поверх содержимого слайдера */
}
.slick-prev:before,
.slick-next:before
{
  font-family: 'slick';
  font-size: 4vw !important;
  line-height: 1 !important;

  opacity: 1 !important;
  color: white !important;
  background-color: transparent !important; /* Убираем фоновый цвет */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev,
.slick-next {
  background-color: transparent !important; /* Убираем фоновый цвет */
  color: orange !important; /* Меняем цвет стрелки на белый */
  width: 2.08vw !important;
  height: 2.08vw !important;
  position: absolute !important; /* Позиционирование абсолютное для точного размещения */
  top: 24% !important;
  transform: translateY(-50%); /* Смещение по вертикали */
  z-index: 55 !important; /* Устанавливаем z-index, чтобы быть поверх других элементов */
  background-color: #fff; /* Цвет фона */
  padding: 0.26vw !important; /* Отступ вокруг содержимого */
  /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); !* Тень, указывающая наличие элемента за пределами *!*/
  cursor: pointer; /* Показываем, что элемент является интерактивным */
}
.slick-slider {
  width: 80.46vw;
}
.slick-slider.winnersSearch {
  width: 22.57vw;
  height: 4.17vw;
  margin-left: 61.5vw;
  margin-top: -5vw;
}
.slick-slider.prizesShow {
  width: 47.57vw;
  height: 16.17vw;
  margin-left: 8.5vw;
  margin-top: -1vw;
  margin-bottom: -3vw;
}
.slick-slider.prizesShowMoment {
  width: 47.57vw;
  height: 16.17vw;
  margin-left: 8.5vw;
  margin-top: -1vw;
  margin-bottom: -3vw;
}
.winnersSearch .slick-list {
  margin-left: 7vw !important;
}
.slick-slider.winnersSearchCodes {
  width: 22.57vw;
  height: 4.17vw;
  margin-left: 0.5vw;
  margin-top: 3vw;
  z-index: 5 !important;
}
.winnersSearchCodes .slick-list {
  margin-left: 3vw !important;
}
.winnersSearch .slick-list {
  margin-left: 7vw !important;
}
.slick-slider.center {
  z-index: 2;
  background-color: rgba(245, 245, 237, 0.7);
  width: 72.57vw;
  height: 26.17vw;
  margin-left: -1.5vw;
  margin-top: 7vw;
  border-radius: 1.43vw;
}
.prizesShow .slick-prev {
  margin-left: 2.3vw !important;
  margin-top: 0vw;
}
.prizesShow .slick-next {
  margin-right: 1vw !important;
  margin-top: 0vw !important;
}
.prizesShowMoment .slick-prev {
display: none !important;
}
.prizesShowMoment .slick-next {
  display: none !important;
}


.center .slick-list {
  margin-left: 0vw !important;
}
.winnersSearchCodes .slick-prev::before
{
  margin-left: -6vw !important;
  margin-top: 3vw !important;
}
.center .slick-prev {
  margin-left: -7.3vw !important;
  margin-top: 5vw;
}
.center .slick-next {
  margin-left: 5vw !important;
  margin-top: 5vw !important;
}
.winnersSearchCodes .slick-prev,
.winnersSearchCodes .slick-next
{
  margin-top: 0.5vw !important;
}
.scroll-container {
  position: relative;
  overflow: hidden;
  width: 79.24vw;
  height: 60vw;
}
.simplebar-wrapper {
  height: auto !important;
}

.scroll-content {
  z-index: 1;
  background: rgba(91, 171, 113, 1);
  position: relative;
  height: 100%;
  width: 100%;
}
.winnersSearch .slick-prev,
.winnersSearch .slick-next
{
}
.winnersSearch .slick-prev:hover,
.winnersSearch .slick-prev:focus,
.winnersSearch .slick-next:hover,
.winnersSearch .slick-next:focus
{
}
.winnersSearch .slick-prev::before,
.winnersSearch .slick-next::before
{
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: .75;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scrollbar-track {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0.37vw;
  background-color: #f1f1f1;
  opacity: 0.5;
  border-radius: 0.29vw;
  margin-left: 4.2vw;
  margin-top: 1.8vw;
  height: 27.82vw;
}
.green-background-full-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 53.47vw;
  height: 34.72vw;
  border-radius: 2vw;
  background: linear-gradient(to top, rgba(0, 98, 65, 1), rgba(91, 171, 113, 1));
}

.scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.29vw;
  background-color: yellow;
  border-radius: 0.29vw;
  height: 3.66vw;
  transition: background-color 0.3s, transform 0.3s; /* Добавлено свойство transform в transition */
}

.scroll-content::-webkit-scrollbar {
  display: none;
}

.scroll-content:hover .scrollbar-thumb {
  background-color: #555;
}
.scroll-content:hover .scrollbar-thumb:active,
.scroll-content:hover .scrollbar-thumb:focus {
  transform: translateY(100%); /* Добавлено свойство transform для передвижения скролла */
}
.scroll-content::-webkit-scrollbar {
  display: none;
}

.scroll-content:hover .scrollbar-thumb {
  background-color: #555;
}

.scroll-content:hover .scrollbar-thumb:active {
  background-color: #333;
}

.scroll-content:hover .scrollbar-thumb:active,
.scroll-content:hover .scrollbar-thumb:focus {
  transform: translateY(100%);
}

.custom-simple-bar2 .simplebar-track.simplebar-vertical {
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  margin-left: 73.6vw;
  margin-top: 0.75vw;
  width: 0.44vw !important;
  height: auto;
  opacity: 1 !important;
  border-radius: 50px; /* Преобразовать в круг */
  z-index: 2;
  overflow: visible; /* Разрешаем элементам выходить за пределы блока */
}
.custom-simple-bar2 .simplebar-scrollbar::before {
  display: block;
  position: absolute;
  z-index: 9999;
  width: 0.28vw !important;
  height: auto;
  margin-left: -0.01vw;
  background-color: rgba(0, 98, 65, 1) !important;
}

.simplebar-scrollbar {
  /* стили скроллбара */
  display: block;
}
.simplebar-scrollbar {
  /* стили скроллбара */
  transform: translate3d(0px, 0px, 0px); /* или любой другой подходящий translation */
}

.custom-simple-bar2 .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
  background-color: rgba(0, 98, 65, 1);
}



.custom-simple-bar2 .simplebar-content {
  overflow: visible; /* Разрешаем элементам выходить за пределы блока */
  height: auto;
}
.custom-simple-bar2 .simplebar-scrollable-y {
  max-height: 165vw !important;


}
.slider-search-winnersall {
  padding: 0.89vw 0.6vw 0.89vw 0.6vw;
  margin: 0vw 0vw 0vw 0vw;
  border-radius: 1.04vw;
  color: white;
  font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
  font-weight: 400;
  font-size: 2vw;
  line-height: 2.71vw;
  background-color: rgba(0, 98, 65, 1);
}
.slider-codes-winnersall {
  padding: 1.8vw 2.5vw 1.79vw 2.8vw;
  margin: 0vw 0vw 0vw 0vw;
  border-radius: 4.04vw;
  color: rgba(0, 98, 65, 1);
  font-family: 'Acrom Medium', Inter, sans-serif;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.53vw;
  background-color: white;
}
.slider-cards-winnersall-img {
  width: 18.75vw;
  height: 24.31vw;
  margin: 2vw;
}
.slider-cards-winnersall-img a {

}
.slider-cards-winnersall-img.blurred {
  opacity: 0.2;
}
.slider-cards-winnersall {
  width: 18.75vw;
  height: 24.31vw;
  padding: 1.8vw 2.5vw 1.79vw 2.8vw;
  margin: 0vw 0vw 0vw 0vw;
  border-radius: 4.04vw;
  color: rgba(0, 98, 65, 1);
  font-family: 'Acrom Medium', Inter, sans-serif;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.53vw;
}
.text-img-name {
  margin: 1.2vw 0vw 0vw 0vw;
  width: 0vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-weight: 400;
  font-size: 2.0vw;
  line-height: 1.96vw;
  color: white;
  float: left;
  white-space: nowrap;
}
.text-img-name.number-open {
  margin: 6.2vw 0vw 0vw 1.5vw;
  width: 0vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-weight: 400;
  font-size: 2.43vw;
  line-height: 2.43vw;
  color: white;
  float: left;
  white-space: nowrap;
}
.text-img-name.down-open {
  position: absolute;
  margin: 9.2vw 0vw 0vw 1.5vw;
  width: 25.5vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-weight: 400;
  font-size: 1.74vw;
  line-height: 1.74vw;
  text-transform: none;
  text-align: left;
  color: white;
  float: left;
  white-space: normal;
}
.text-img-name.down-open-down {
  position: absolute;
  margin: 12.2vw 0vw 0vw 1.5vw;
  width: 23.5vw;
  font-family: Inter;
  font-weight: 400;
  font-size: 0.7vw;
  line-height: 0.7vw;
  text-transform: none;
  text-align: left;
  color: white;
  float: left;
  white-space: normal;
}
.text-img-name.down {
  position: absolute;
  margin: 4.2vw 0vw 0vw 0vw;
  width: 13.5vw;
  font-family: Inter;
  font-weight: 400;
  font-size: 0.97vw;
  line-height: 1.16vw;
  text-transform: none;
  text-align: left;
  color: white;
  float: left;
  white-space: normal;
}
.cards-img {
  position: relative;
  z-index: 1;
  width: 14.75vw;
  height: auto;
  margin: 0vw 0vw 0vw 0vw;
}
.green-background {
  padding-top: 0.7vw;
  padding-left: 0.7vw;
  position: relative;
  z-index: 1;
  width: 15.75vw;
  height: 22vw;
  border-radius: 2vw;
  background: linear-gradient(to top, rgba(0, 98, 65, 1), rgba(91, 171, 113, 1));
}
.green-background:hover {
  cursor: pointer;
}

.cards-img.number {
  margin: 0.15vw 0vw 0vw 0.15vw;
  position: absolute;
  z-index: 2;
  width: 2.75vw;
  height: auto;
}
.cards-img.number-open {
  margin: 1.05vw 0vw 0vw -25.85vw;
  position: absolute;
  z-index: 2;
  width: 3.75vw;
  height: auto;
}
.cards-img.up{
  margin: 7.05vw 0vw 0vw 13.15vw;
  position: absolute;
  z-index: 2;
  width: 1.75vw;
  height: auto;
}
.cards-img.cursor-pointer {
  cursor: pointer;
}
.cards-img.enlarge {
  /*transform: scale(1.1); !* Изображение увеличивается в 1.2 раза *!*/
  /*transition: transform 0.3s ease; !* Анимация изменения масштаба *!*/
}
.cards-img {
  /*transition: transform 0.5s ease; !* Задаем CSS transition для свойства transform *!*/
}

.enlarge {
  /*transform: scale(1); !* Увеличиваем размер при нажатии *!*/
}

.shrink {
  transform: scale(1); /* Возвращаем размер к обычному значению */
}

.slick-list {
  margin-left: 5vw !important;
}
.how-tickets-img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2vw 0vw 0vw 0vw;
}
.how-tickets-img, .how-tickets-img-second {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
.how-tickets-img-second {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -1vw 0vw 0vw -1.5vw;
  background-color: #8080807F;
  border: 1px solid rgba(3, 190, 83, 0.5);
  border-radius: 0.52vw;
  box-shadow: 0 0 0.26vw #009640;
  height: 4.5vw;
  z-index: 11;
  padding-left: 2vw;
  padding-top: 1.3vw;
  padding-bottom: 0.8vw;

}
.how-full4-img,
.how-full44-img,
.how-full444-img,
.how-full4444-img {
  margin-right: 2vw;
  width: 100%;
}
.back-img {
  margin: 3vw 0vw 0vw 3vw !important;
  width: 50% !important;
}
.back-img.how-prizes {
  opacity: 0.75;
  margin: -2.4vw 1vw 1.2vw 1vw !important;
  width: 81% !important;
}
.back-img.how-prizes-new {
  opacity: 0.75;
  margin: 0vw 3vw 0.2vw 0.35vw !important;
  width: 84% !important;
}
.img-bonus {
  width: 7vw !important;
  margin: -6.4vw 0vw 0vw 2.1vw !important;
  position: absolute;
}
.img-bonus-cuva {
  width: 7vw !important;
  margin: -6.1vw 0vw 0vw 1.45vw !important;
  position: absolute;
}
.test-img {
  margin: -18vw 0vw -0.4vw 7.25vw !important;
  position: relative !important;
  width: 21% !important;
}
.test-img.pol10 {
  margin: -18.7vw 0vw -0.97vw 7.05vw !important;
  position: relative !important;
  width: 22.5% !important;
}
.test-img.pol15 {
  margin: -18.9vw 0 -2.0vw 7.05vw !important;
  position: relative !important;
  width: 23.5% !important;
}
.test-img.for-05 {
  margin: -19vw 0vw -0.8vw 6.95vw !important;
  position: relative !important;
  width: 23.8% !important;
}
.test-img.img33 {
  margin: -22vw 0vw -2.2vw -3.3vw !important;
  position: relative !important;
  width: 92% !important;
}
/*.slick-prev,.slick-prev:hover,.slick-prev:focus {*/

/*  background: url("./image/img_63.png") no-repeat!important;*/
/*}*/
/*.slick-next,.slick-next:hover,.slick-next:focus {*/
/*  background: url("./image/img_63.png") no-repeat!important;*/
/*}*/
/*.slick-prev:before:hover {*/
/*  background-color: black !important;*/
/*}*/
.lf-text {
  margin: 3.5vw 0vw 0vw 3.3vw;
  display: block;
  position: relative;
  width: 14vw;
  height: 20vw;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.74vw;
  line-height: 1.67vw;
  color: rgba(0, 98, 65, 1);
}
.how-full4-img img {
  /*border: 0.35vw solid white;*/

}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.how-full4-img img,
.how-full44-img img,
.how-full444-img img,
.how-full4444-img img {
  margin-right: 2vw;
  width: 70%;
  transition: transform 0.5s ease;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); !* добавляем пассивное свечение *!*/
  position: relative;
}
.how-full4-img img::after,
.how-full44-img img::after,
.how-full444-img img::after,
.how-full4444-img img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: blink 2s infinite; /* применяем анимацию мигания */
  opacity: 0;
}
/*.how-full4-img img:hover,*/
/*.how-full44-img img:hover,*/
/*.how-full444-img img:hover,*/
/*.how-full4444-img img:hover {*/
/*  !*transform: scale(1.1); !* увеличиваем масштаб при наведении *!*!*/
/*  box-shadow: 0 0 0.3vw rgba(0, 0, 0, 0.5); !* увеличиваем масштаб и изменяем свечение при наведении *!*/
/*}*/
/*.how-full4-img img:hover::after,*/
/*.how-full44-img img:hover::after,*/
/*.how-full444-img img:hover::after,*/
/*.how-full4444-img img:hover::after {*/
/*  opacity: 1;*/
/*}*/
.for-test-img img {
  width: 86.1%;
  margin-left: -1vw;
}
.for-test-img-test img {
  width: 65%;
  margin-left: -3.5vw;
}
.for-test-img-second img {
  width: 64.6%;
  margin-left: -7.1vw;
}
.for-test-img-third img {
  margin-left: -10.5vw;
}

.how-full44-img-second img {
  width: 80%;
}
.how-full4-img-second img{
  width: 80%;
}
.how-full412-img-second img {
  width: 90%;
  margin-left: -0.4vw;
}
.how-full444-img-second img{
  width: 80%;
}
.how-full4444-img-second img{
  width: 70%;
  margin-left: 1vw;
  margin-top: -0.2vw;
}
.how-full4444-img-second {
  margin-top: 0.45vw;
}
.how-full44445-img-second img {
  width: 90%;
  margin-left: -0.4vw;
  transition: opacity 0.3s ease; /* добавляем плавный переход для свойства opacity */
  opacity: 1;
}
.how-full44445-img-second img {
}

.how-full44445-img-second img:hover {
  transition: opacity 0.3s ease; /* добавляем плавный переход для свойства opacity */
  opacity: 1; /* Плавно скрываем изображение при наведении */
}

.how-full4-img a,
.how-full44-img a,
.how-full444-img a,
.how-full4444-img a {
  display: flex;
  width: 30vw;
}


/*.slick-current {*/

/*  opacity: 1 !important;*/
/*}*/
/*.slick-list {*/
/*  position: relative;*/
/*  display: block;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*.accordion {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 150vw;*/
/*  background-color: darkgoldenrod;*/
/*}*/
.MuiAccordionSummary-content {
  text-align: center !important;
  flex-basis: 80.33% !important;
}
.MuiIconButton-root {
  padding: 0.2vw !important;
}
.MuiSvgIcon-root {
  font-size: 3rem !important;
}
.MuiIconButton-root {
  color: black !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 2.72vw !important;
  margin-top: 1vw !important;
}

.MuiAccordionSummary-root .MuiAccordionSummary-expandIcon,
.MuiAccordionSummary-root .MuiIconButton-root {
  transition: all 0.3s ease; /* Плавный переход для всех свойств при hover */
}



.MuiAccordionSummary-root .MuiIconButton-root {
  color: white !important;
}
/*.MuiAccordionSummary-root:hover .MuiIconButton-root {*/
/*  color: #c2b8b8 !important;*/
/*}*/
.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIcon {
  color: #286043 !important;
}
.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIcon {
  background-color: white !important;
}
.MuiAccordionSummary-root .MuiAccordionSummary-expandIcon {
  background-color: rgba(0, 98, 65, 1) !important;
}
.MuiAccordionSummary-root {
  display: flex;
  z-index: 5 !important;
  padding: 0.78vw 1.30vw !important;
  min-height: 9.24vw;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /*border: 2px solid #286043 !important;*/
  /*border-radius: 1.8vw !important;*/
}
.MuiButtonBase-root {
  border-radius: 2vw;
}
.makeStyles-heading-2 {

  font-size: 1.9375rem !important;
  flex-basis: 100.33% !important;
  font-weight: 700 !important;
  flex-shrink: 0;
}
.MuiTypography-body1 {
  font-size: 1.25vw !important;
  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.53vw !important;
  letter-spacing: 0.00938em;
  text-align: left !important;
}
.makeStyles-line-5 {
  width: 97% !important;
  margin: 0.28vw 0vw 1.67vw 0vw !important;
  border-top: 1px solid #ccc;
  background-color: #fff;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 1.04vw 1.94vw 4.17vw 4.17vw !important;
}
.heading-id {
  font-weight: 500;
  font-family: Inter;
  margin-left: 2.5vw;
  font-size: 4.17vw;
  line-height: 5.07vw;

}
.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-content {
  color: white !important;
}
.heading-name {
  margin-left: 9vw;
  margin-top: -3.5vw;
  font-weight: 500;
  font-family: 'Inter';
  font-size: 2.08vw;
  line-height: 2.50vw;
}









.footer {
  display: flex;
  font-size: 2vw;
  justify-content: space-between;
  align-items: center;
  margin-top: -2.7vw;
  padding-top: 2.46vw;
  padding-bottom: 5.5vw;
}

.left-footer {
  font-family: Inter;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.53vw;
  color: rgba(245, 245, 237, 1);
  width: 28.06vw;
  text-align: left;
  margin-right: auto;
  margin-left: 11vw;
  margin-top: 4.2vw;
}

.right-footer {
  margin: -15.7vw 5vw 0vw 0vw;
  font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
  font-weight: 400;
  font-size: 3.47vw;
  line-height: 4.31vw;
  color: rgba(245, 245, 237, 1);
  width: 50%;
  text-align: center;
  margin-left: auto;
}
.footer-padding-text {
  margin-top: 2vw;
}
.footer-padding-text.new {
  /*width: 50vw;*/
  /*position: absolute;*/
  margin-top: 2vw;
}
.buttons {
  margin-top: -2vw;
  padding-bottom: 5vw;
}
.buttons button {
  margin-right: 10px;
}
.footer-left-button {
  font-size: 1.25vw;
  font-family: Inter;
  line-height: 1.53vw;
  font-weight: 400;
  width: 22.57vw;
  height: 4.58vw;
  border-radius: 0.97vw;
  color: white;
  background-color: rgba(0, 98, 65, 1);
  border: 1px solid white;
}
.footer-right-button {
  font-size: 1.39vw;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.94vw;
  width: 15.21vw;
  height: 4.72vw;
  border-radius: 0.97vw;
  background-color:rgba(162, 212, 65, 1);
  border: 1px;
}
.footer-left-button,
.footer-right-button {
  transition: background-color 0.3s, color 0.3s; /* Добавляем плавный переход для изменения цвета */
  cursor: pointer; /* Изменяем форму курсора на указатель при наведении */
}

.footer-left-button:hover {
  background-color: #0f5132; /* Изменяем цвет фона при наведении */
  color: #ffffff; /* Изменяем цвет текста при наведении */
}

.footer-right-button:hover {
  background-color: rgba(162, 212, 65, 0.8); /* Изменяем цвет фона при наведении */
  color: #ffffff; /* Изменяем цвет текста при наведении */
}


@keyframes chaotic-movement {
0% {
  transform: translate(0, 0) rotate(0deg);
}
25% {
  transform: translate(10px, -10px) rotate(10deg);
}
50% {
  transform: translate(-10px, 10px) rotate(-10deg);
}
75% {
  transform: translate(10px, 10px) rotate(-20deg);
}
100% {
  transform: translate(0, 0) rotate(0deg);
}
}


.image {
animation: chaotic-movement 15s infinite linear;
backface-visibility: hidden;
}
/* PRODUCTS********************************** */
.products {
  display: flex;
  height: 43vw;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(248, 214, 72, 1);
}




















/* HOW ********************************** */
.wrapper-how {
  width: 88.6vw;
  margin-left: 7vw;
  overflow: hidden;
}
.how {
  background-size: cover;
  height: 35vw;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  min-width: initial;
  scroll-behavior: smooth;
  font-size: 0.83vw;
  line-height: 1.5;
  margin-top: 0;
  padding-top: 1.46vw;
  background-color: rgba(91, 171, 113, 1);
}
.prizes-text {
  margin: 3vw auto;
  margin-top: 0.8vw;
  margin-left: -7.8vw;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
  color: #EEF3EC;
  font-size: 3.47vw;
  line-height: 3.33vw;
  width: 101.75vw;
  height: 8.05vw;
}
.prizes-text-next {
  margin: 3vw auto;
  margin-top: 3vw;
  margin-left: -7.8vw;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
  color: #EEF3EC;
  font-size: 3.47vw;
  line-height: 3.33vw;
  width: 101.75vw;
  height: 8.05vw;
}
.center-container {
  margin: 4vw 2.67vw 0vw 0vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 237, 0.7);
  border-radius: 2.43vw;
}

.container-sub {
  margin-left: 2vw;
  width: 85.69vw;
  height: 15.35vw;
}

.main-div {
  width: 83%;
  height: 4%;
  border-radius: 35px;
  position: absolute;
  display: flex;
  justify-content: space-around;
}

.sub-div {
  width: 30%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  /*border-bottom: 1px solid rgba(0, 98, 65, 1);*/
  /*background-color: rgba(0, 98, 65, 1); !* Цвет фона подложки *!*/
}
.sub-div-text {
  width: 18.96vw;
  height: 5vw;
  font-family: Inter;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 1.53vw;
  color: rgba(0, 98, 65, 1);
}
.sub-div img {
  margin: -5vw 0vw 2vw -1vw;
  max-width: 44%;
  height: auto;
  border-radius: 50%;
}

.sub-div p {
  margin: -0.9vw 0vw 0vw 2.5vw;
}
.vertical-line {
  margin-top: 3vw;
  width: 0.14vw;
  height: 66%;
  background-color: rgba(0, 98, 65, 1);
}



/* HOW-PRIZES ********************************** */
.wrapper-how-prizes {
  width: 88.6vw;
  margin-left: 7vw;
  overflow: hidden;
}
.how-prizes {
  background-size: cover;
  height: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  min-width: initial;
  scroll-behavior: smooth;
  font-size: 0.83vw;
  line-height: 1.5;
  margin-top: 0;
  padding-top: 1.46vw;
  background-color: rgba(91, 171, 113, 1);
}
/*.prizes-text-next {*/
/*  font-size: 24px;*/
/*  font-weight: bold;*/
/*  margin-bottom: 20px;*/
/*}*/

.prizes-content {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
}
.left.lc {
  display: flex;
  flex-direction: column;
  margin: 4.5vw 0vw 0vw 5.5vw;
}
.million {
  margin: -4vw 0vw 1vw 1.9vw;
  width: 26.88vw;
  height: auto;
}
.left-p-name-low {
  margin: 0vw 0vw 0vw 7.1vw;
  width: 15.97vw;
  height: 5vw;
  font-size: 1.11vw;
  line-height: 1.18vw;
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-weight: 500;
}

.right {
  margin: -4vw 0vw 0vw -10vw;
  flex: 1 1;
  right: 0;
  display: flex;
  flex-direction: column;
}
.right.lc {
  margin: -3.8vw 0vw 0vw -15.45vw;
  flex: 1 1;
  right: 0;
  display: flex;
  flex-direction: column;
}
.right-p-name {
  margin: -1.15vw 0vw 2vw 4vw;
  font-family: Inter;
  font-weight: 700;
  font-size: 1.74vw;
  line-height: 1.67vw;
  text-transform: uppercase;
  color: white;
}
.right-p-name.down {
  margin: 4.6vw 0vw 2vw 4vw;

}
.right-p-name.left-down {
  margin: 0.85vw 0vw 1vw 1.6vw;

}
.left-p-name.lc {
  margin: 2.35vw 0vw -1vw 3.4vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-weight: 400;
  font-size: 2.50vw;
  line-height: 3.13vw;
  text-transform: uppercase;
  color: rgba(0, 98, 65, 1);
}
.right-p-name.lc {
  margin: 2.35vw 0vw -1vw 8.2vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-weight: 400;
  font-size: 2.50vw;
  line-height: 3.13vw;
  text-transform: uppercase;
  color: rgba(0, 98, 65, 1);
}
.new-block {
  background-color: rgba(140, 196, 155, 1);
  margin: -1vw 0vw 0vw 19.7vw;
  width: 34.72vw;
  height: 7.01vw;
  border-radius: 3.47vw;
}
.right-p-prizes {
  width: 12vw;
  margin-left: 2vw;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.53vw;
  line-height: 1.60vw;
  color: white;
}
.right-p-prizes-little {
  width: 11vw;
  margin-left: 0.5vw !important;
  font-family: Inter;
  font-weight: 300;
  font-size: 1.18vw;
  line-height: 1.25vw;
  color: white;
}
.right-p-prizes-moment {
  margin-top: -0.5vw;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.53vw;
  line-height: 1.60vw;
  color: white;
}
.images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.images-moment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1.2vw;
  margin-top: 1vw;
}
.images-phone {
  display: none;
}
.images-moment img {
  width: 8.75vw;
  height: auto;
  margin: 1.15vw;
  margin-top: 0vw;
  margin-left: 1vw;
}

.images img {
  margin-left: 5vw;
  margin-right: 0vw;
  width: 8.75vw;
  height: 8.75vw;
  height: auto;
  margin: 0.35vw;
}
.image-container {
  text-align: center;
  margin-right: -0.7vw;
  margin-left: 4.1vw;
}
.image-container-moment {
  text-align: center;
  margin-right: -0.7vw;
  margin-left: 4.1vw;
  margin-top: -1vw;
  margin-bottom: -1vw;
}
.image-container-for-phone {
  display: none;
}

.image-container p {
  margin: 0.19vw -6vw 2vw -1.6vw;
}
/*.slick-slider.prizesShow.slick-list {*/
/*  margin-left: 10vw !important;*/
/*}*/





/* WINNERS ------------------------------------------------------ */
.winners {
  height: 74.5vw;
  width: 100%;
  margin-top: -6.1vw;
  padding-top: 0.56vw;
  background-color: rgba(91, 171, 113, 1);
}
.winners.all {
  height: auto;
  margin-top: -7.1vw;
}
.winners-text {
  z-index: 5;
  width: 50vw;
  margin-top: 8.5vw;
  margin-left: 9.6vw;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 6.32vw;
  line-height: 6.04vw;
  color: #EEF3EC;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  text-align: left;
  color: rgba(0, 98, 65, 1);
}
.lc-text-profile-d {
  margin: -5.2vw 0vw 0vw 12.5vw;
  width: 55.76vw;
  height: 4.31vw;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3.47vw;
  line-height: 4.31vw;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  color: rgba(0, 98, 65, 1);
}
.lc-text-profile-d-down {
  margin: 0.8vw 0vw 0vw 12.6vw;
  width: 55.49vw;
  height: 7.50vw;
  text-transform: none;
  text-align: center;
  font-weight: 400;
  font-size: 2.08vw;
  line-height: 2.50vw;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
}
.lc-text {
  position: relative;
  z-index: 10;
  margin: -3.6vw 0vw 0vw 1.8vw;
  font-size: 1.39vw;
  line-height: 1.67vw;
  font-weight: 400;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
  text-decoration: underline;
}
.lc-text.lc-settings:hover {
  cursor: pointer;
}

.winners-text.winners-main {
  z-index: 5;
  width: 50vw;
  margin-top: 8.5vw;
  margin-left: 36.4vw;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3.47vw;
  line-height: 4.31vw;
  color: #EEF3EC;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.winners-text.lc {

  z-index: 5;
  width: 50vw;
  margin-top: 8.5vw;
  margin-left: 32.4vw;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3.47vw;
  line-height: 4.31vw;
  color: #EEF3EC;
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.winners-main.left {
  margin-left: 14.4vw;
}
.winners-main.center {

  margin-top: 10vw;
  margin-left: 41.8vw;
  margin-bottom: 9.9vw;
}
.winners-text.second-second {
  margin: 6vw 0vw 2.2vw 26.1vw;
}
.winners-text.second {
  margin: 6vw 0vw 2.2vw 26.7vw;
}
.for-faq {
  font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
  font-size: 3.47vw;
  font-weight: 400;
  line-height: 4.31vw;
  text-transform: none;
  text-align: left;
  margin-left: 29.9vw;
  margin-top: 11.8vw;
  margin-bottom: 4vw;
  color: rgba(0, 98, 65, 1);
}
.for-faq.faq {
  margin-top: 4.8vw;
}
.for-faq.lc {
  color: rgba(0, 98, 65, 1);
  margin-left: 25.5vw;
  margin-top: 3.4vw;
}
.for-slider {
  z-index: 5;
  width: 38vw;
  font-size: 3.47vw;
  text-transform: none;
  text-align: center;
  margin: 6vw auto;
  margin-top: 2.2vw;
  margin-left: 31vw;
  color: rgba(0, 98, 65, 1);
  line-height: 1;
}
.winners-top-input {
  display: flex;
  text-align: center;
  margin-left: 13.875vw;
  margin-top: 2.41666666666667vw;
}
.winners-left-input {
  display: flex;
  width: 23.06vw;
  height: 3.66vw;
}
.winners-right-input {
  display: flex;
  width: 23.06vw;
  height: 3.66vw;
  margin-left: 12.5vw;
}
.winners-panel {
  padding-top: 5.13vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

}
.winners-items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}


.winners-item {
  margin-left: 0.625vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.989583333333333vw 1.5625vw;
  white-space: nowrap;
  cursor: pointer;

  border: 0.104166666666667vw solid #eef3ec;
  border-radius: 4.21875vw;
}
.winners-item-title {
  color: #EEF3EC;
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 1.25vw;
  text-align: center;
}
.winners-js-form {
  display: block;
  margin-top: 0em;
}

.winners-js-form-search {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 23.4375vw;
  margin-left: 2.041666666666667vw;
}
.winners-button-field {
  width: 0.9375vw;
  height: 0.9375vw;
  position: absolute;
  top: 50%;
  left: 1.666666666666667vw;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  z-index: 1;
}
.table-body-winners {
  position: relative;
  z-index: 5;
  background-color: rgba(245, 245, 237, 0.7);
  border: .07vw solid #ffffff4d;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  display: flex;
  flex-flow: row nowrap;
  font-family: Halvar Mittelschrift Rg, sans-serif;
  font-size: 1.17vw;
  font-weight: 400;
  height: 5.23vw;
  justify-content: flex-start;
  margin-top: 0.652083vw;
  text-align: center;
  width: 71.35vw;
}
.table-body-winners.down {
  margin-bottom: 22vw;
}
.table-body-winners.mill {
  margin-bottom: 19vw;
}
.winners-search-field {
  width: 100%;
}
.field-label {
  position: relative;
  display: block;
  width: 100%;
}
.field-input {
  background: #234a30;
  border-radius: 4.21875vw;
  border: none;
  color: #eef3ec;
}
.field-placeholder {
  padding: 1.09375vw 4.479166666666667vw 1.09375vw 3.072916666666667vw;
  font-size: 1.041666666666667vw;
  line-height: 1.25vw;
  width: 23.4375vw;
}
.winners-tabs-content {
  height:auto;
}
.winners-tabs-content-ticket {
  margin-left: 3.2vw;
  margin-top: -1.3vw;
}
.table-ticket {
  border-radius: 0.520833333333333vw;
  padding: 1.041666666666667vw 0 1.5625vw;
  width: 50.8125vw;
}
.table {
  border-radius: 0.520833333333333vw;
  padding: 1.041666666666667vw 0 1.5625vw;
  width: 77.8125vw;
  min-height: 20vw;
}
.table-head {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  color: #FFFFFF;
  line-height: 1.63vw;
  margin-top: 0.55vw;
  font-weight: 400;
  font-size: 1.17vw;
  font-family: 'Acrom Medium', Arial, sans-serif;
}
.head-colm-date {
  min-width: 25.364583vw;
  margin-top: -0.5vw;

}
.head-colm-prize {
  min-width: 13.427083333333336vw;
  padding: 0 1.445833333333333vw 0 16.345833333333333vw;
  margin-top: -0.7vw;
}
.head-colm-phone-ticket {
  min-width: 16.614583333333332vw;
  margin-top: -0.35vw;
  margin-left: -0.2vw;
}
.head-colm-phone {
  min-width: 16.614583vw;
  margin-top: -0.7vw;
  margin-left: 1.8vw;
}
.head-colm-date-ticket {
  min-width: 5.364583vw;
  margin-top: -0.3vw;
  margin-left: -2.7vw;

}
.head-colm-date {
  min-width: 25.364583vw;
  margin-top: -0.5vw;

}
.head-colm-prize1-ticket {
  min-width: 13.427083333333336vw;
  /* margin-top: 1.7vw; */
  margin-left: -0.9vw;
}
.head-colm-prize1 {
  font-size: 1.74vw;
  line-height: 2.08vw;
  font-weight: 600;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
  width: 15.427083vw;
  margin-top: 1.7vw;
  margin-left: -1vw;
}
.head-colm-phone1-ticket {
  min-width: 17.364583333333332vw;
  /* margin-top: 0.75vw; */
  margin-left: -0.9vw;
}
.head-colm-phone1 {
  font-size: 1.25vw;
  line-height: 1.5vw;
  letter-spacing: 0.07vw;
  font-weight: 500;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
  min-width: 17.364583333333332vw;
  margin-top: 1.75vw;
  margin-left: 1.1vw;
}
.head-colm-date1-ticket {
  min-width: 15.364583vw;
  height: auto;
  margin-top: 1.65vw;
}
.head-colm-date1 {
  min-width: 20.364583vw;
  margin-top: 1.65vw;
  margin-left: auto; /* Чтобы прижать к правому краю */
}
.head-colm-date1-phone {
  display: none;
}
.winners-all {
  background-color: rgba(0, 98, 65, 1);
  border: .07vw solid #ffffff4d;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  font-family: Halvar Mittelschrift Rg, sans-serif;
  font-size: 1.17vw;
  font-weight: 400;
  height: 4.03vw;
  justify-content: flex-start;
  margin-left: 0.1vw;
  margin-top: 1.352083vw;
  text-align: center;
  width: 71.35vw;
  display: flex; /* Добавлено */
  align-items: center; /* Добавлено */
}
.winners-all-a {
  font-size: 1.74vw;
  line-height: 1.67vw;
  font-family: Inter;
  font-weight: 600;
  color: rgba(245, 245, 237, 1);
  text-decoration: none;
  margin: 0 auto; /* Добавлено */
}
.winners-all-a.faq {
  font-size: 1.74vw;
  line-height: 1.67vw;
  font-family: Inter;
  font-weight: 600;
  color: rgba(245, 245, 237, 1);
  text-decoration: none;
  margin: 0 auto; /* Добавлено */
  background-color: rgba(0, 98, 65, 1);
  border: .07vw solid #ffffff4d;
  padding: 1vw 36vw;
  width: 85.35vw;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  transition: all 0.3s ease; /* добавлена анимация */
}
.winners-all-a.winners-test-a {
  z-index: 2;
  position: relative;
  display: block;
  width: 19.35vw;
  font-size: 1.74vw;
  line-height: 1.67vw;
  font-family: Inter;
  margin-top: -2vw;
  font-weight: 600;
  color: rgba(245, 245, 237, 1);
  text-decoration: none;
  margin: 0 auto;
  background-color: rgba(0, 98, 65, 1);
  border: .07vw solid #ffffff4d;
  padding: 1vw 26vw 1vw 26vw;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  transition: all 0.3s ease;
}
.winners-all-a.winners {
  font-size: 1.74vw;
  line-height: 1.67vw;
  font-family: Inter;
  font-weight: 600;
  color: rgba(245, 245, 237, 1);
  text-decoration: none;
  margin: 0 auto; /* Добавлено */
  background-color: rgba(0, 98, 65, 1);
  border: .07vw solid #ffffff4d;
  padding: 1vw 25vw;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  transition: all 0.3s ease; /* добавлена анимация */
}
.winners-all-a.winners:hover {
  color: #dcdcdc;
  background-color: #286043; /* изменение цвета при наведении */
  transform: scale(1.05); /* увеличение размера элемента при наведении */
}
.winners-all-a.winners-test-a:hover {
  color: #dcdcdc;
  background-color: #286043; /* изменение цвета при наведении */
}
.winners-all-a.faq:hover {
  color: #dcdcdc;
  background-color: #286043; /* изменение цвета при наведении */
}
.example-all-test {
  margin-bottom: 4vw;
  margin-top: 2.652083vw;
}
.example-all-test.winners-test {
  margin-bottom: 0vw;
  margin-top: 1.652083vw;
}
.winners-all.example-all {
  background-color: rgba(0, 98, 65, 1);
  border: .07vw solid #ffffff4d;
  border-radius: 1.04vw 1.04vw 0.35vw 1.04vw;
  font-family: Halvar Mittelschrift Rg, sans-serif;
  font-size: 1.17vw;
  font-weight: 400;
  height: 4.03vw;
  justify-content: flex-start;
  margin-left: 0.2vw;
  margin-top: 1.352083vw;
  text-align: center;
  width: 85.35vw;
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}
.colm-text {
  text-transform: uppercase;
}
.colm-text-ticket {
  text-transform: uppercase;
  font-size: 1.17vw;
  font-family: "Acrom Medium", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.63vw;
}
.colm-ticket-none {
  display: none;
}
.head-colm-prize1-ticket {
  min-width: 13.427083333333336vw;
  /* margin-top: 1.7vw; */
  margin-left: -0.9vw;
}
.head-colm-prize1 {
  width: 6.427083vw;
  margin-top: 1.7vw;
  margin-left: -1vw;
  margin-right: 9vw;
}
.head-colm-name {
  font-size: 1.74vw;
  line-height: 2.08vw;
  font-weight: 600;
  font-family: Inter;
  color: rgba(0, 98, 65, 1);
  width: auto;
  min-width: 38vw;
  margin-top: 1.75vw;
  margin-left: -6.8vw;
  text-align: left;
  letter-spacing: 0.1vw;
}
.head-colm-phone1-ticket {
  min-width: 17.364583333333332vw;
  /* margin-top: 0.75vw; */
  margin-left: -0.9vw;
}
.head-colm-phone1 {
  min-width: 0.364583vw;
  margin-top: 2vw;
  padding-left: 8.2vw;
}
.head-colm-date1-ticket {
  min-width: 15.364583vw;
  height: auto;
  margin-top: 1.65vw;
}
.head-colm-date1 {
  min-width: 8.364583vw;
  margin-top: 1.65vw;
  margin-left: auto; /* Чтобы прижать к правому краю */
}
.winners-block-img {
  display: inline-block;
  background-color: rgba(245, 245, 237, 1);
  width: 3.96vw;
  height: 3.96vw;
  margin: -1vw 0vw 0vw 2vw;
  border-radius: 0.69vw;
}
.winners-prize-img {
  height: auto;
  margin: 1vw 0 0;
  width: 2.06vw;
}
.colm-text {
  text-transform: uppercase;
}
.colm-text-ticket {
  text-transform: uppercase;
  font-size: 1.17vw;
  font-family: "Acrom Medium", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.63vw;
}
.colm-ticket-none {
  display: none;
}
.decl-text {
  font-family: 'Halvar Mittelschrift Rg', sans-serif;
  width: 42.35vw;
  margin-top: -3.5vw;
  font-size: 1.32vw;
  line-height: 1.76vw;
  margin-bottom: 4.5vw;
  text-align: center;
  color: white;
  margin-left: -1vw;
}
.colm-tel-none {
  width: 17.60vw;
  height: 5.87vw;
  display: inline-block;
  float: left;
  text-align: left;
  margin-left: -9vw;
  margin-top: 5.1vw;
}

.colm-data-none {
  width: 17.60vw;
  height: 5.87vw;
  text-align: left;
  display: inline-block;
  float: left;
  margin-left: -9vw;
  margin-top: 2.4vw;
}

.colm-prize-none {
  width: 17.60vw;
  text-align: left;
  height: 5.87vw;
  display: inline-block;
  float: left;
  margin-left: -9vw;
  margin-top: 2.4vw;
}

.colm-tel {
  float: left;
  margin-top: 5.3vw;
  margin-left: 6.5vw;
  text-align: left;
}

.colm-data {
  float: left;
  text-align: left;
  margin-top: 2.2vw;
  margin-left: 6.5vw;
}

.colm-prize {
  float: left;
  text-align: left;
  margin-top: 2.2vw;
  margin-left: 6.5vw;
  width: 42.67vw;
  height: 11.73vw;
}
.decl-bonus-text {
  font-family: 'Halvar Mittelschrift Rg', sans-serif;
  width: 42.35vw;
  margin-top: -1.5vw;
  font-size: 1.32vw;
  line-height: 1.76vw;
  margin-bottom: 3.5vw;
  text-align: center;
  color: white;
  margin-left: -1vw;
}
.colm-data-ticket-none {
  display: none;
}
.colm-status-ticket-none {
  display: none;
}
.colm-text1-ticket {
  margin-left: 3vw;
  margin-top: 0vw;
}
.colm-text1-data-ticket {
  margin-left: -4.3vw;
  margin-top: 0vw;
}
.centered-ticket-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-ticket {
  display: flex;
  align-items: center;
}
.colm-text1-status-ticket1-fail {
  width: 17.59vw;
  height: 2.64vw;
  margin-left: 1vw;
  margin-top: 0.1vw;
  margin-bottom: 1.4vw;
}
.colm-text1-status-ticket1-complete {
  display: inline-block;
  width: 19.59vw;
  height: auto;
  margin-left: 2.75vw;
  margin-top: 0.1vw;
  margin-bottom: 1.4vw;
  text-align: left;
}
.colm-text1-status-ticket1-check {
  width: 19.59vw;
  height: 2.64vw;
  margin-left: -2.15vw;
  margin-top: -0.4vw;
  margin-bottom: 1.4vw;
  text-align: left;
}
.colm-text1-status-ticket3 {
  margin-left: -11.2vw;
  margin-top: -2.9vw;
  margin-bottom: 1.4vw;
}
.table-body-ticket {
  color: #eef3ec;
  font: 500 1.041666666666667vw 'Montserrat', sans-serif;
  line-height: 1.041666666666667vw;
  margin-left: 0vw;
  margin-top: 0.6vw;
}
.table-body {
  color: #eef3ec;
  font: 500 1.041666666666667vw 'Montserrat', sans-serif;
  line-height: 1.041666666666667vw;
  margin-left: 7.2vw;
  margin-top: 0.7vw;
}
input.new-code {
  font-family: Inter;
  width: 28.56vw;
  height: 4.1vw;
  border: 0.14vw solid red;
  border-radius: 3.68vw;
  padding: 0.35vw;
  font-size: 1.11vw;
  margin-top: 3.1vw;
  margin-left: 16.8vw;
  text-align: center;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
  z-index: 5;
  position: relative;
}
input.new-code:hover {
  border-color: rgba(0, 98, 65, 1); /* Change the border color when input is focused */
  box-shadow: 0 0 0.5vw rgba(0, 98, 65, 1); /* Add a box shadow for visual indication */
  cursor: pointer;
}
input.new-code-profile {
  font-family: Inter;
  width: 28.56vw;
  height: 4.1vw;
  border: 0.14vw solid red;
  border-radius: 3.68vw;
  padding: 0.35vw;
  font-size: 1.11vw;
  margin-top: 3.1vw;
  margin-left: 16.8vw;
  text-align: center;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
  z-index: 5;
  position: relative;
}
input.new-code-profile:hover {
  border-color: rgba(0, 98, 65, 1); /* Change the border color when input is focused */
  box-shadow: 0 0 0.5vw rgba(0, 98, 65, 1); /* Add a box shadow for visual indication */
  cursor: pointer;
}
.container-register {
  padding: 0vw 1vw;
}
.register-inputs {
  border: 1px white solid;
  color: rgba(0, 98, 65, 1);
  font-family: Inter;
  font-size: 1.18vw;
  line-height: 1.46vw;
  width: 34.31vw;
  height: 4.17vw;
  border-radius: 1.04vw;
  background-color: rgba(255, 255, 255, 1);
  padding-left: 1.5vw;
  padding-right: 1.5vw;/* Пример отступа в 1.5vw с левой стороны */
}
.register-inputs:focus {
  outline: none;
}
.register-inputs.code-bottom {
  margin-bottom: -1.2vw;
}
.input-checkbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.register-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3vw;
}
.custom-checkbox.error {
  border: 0.07vw solid red;
}
.register-inputs.error {
  border: 1px solid red;
}
.input-checkbox.error {
  border: 1px solid red;
}

.register-inputs-text.error {
  border: 1px solid red;
}
.form-input-email.error {
  border: 1px solid red;
}
.form-textarea-message.error {
  border: 1px solid red;
}
.popupPassword-input.error {
  border: 1px solid red;
}

.register-button {
  font-size: 1.74vw;
  font-family: Inter;
  line-height: 1.67vw;
  font-weight: 600;
  width: 27.08vw;
  height: 5.07vw;
  border-radius: 3.68vw;
  color: white;
  background: linear-gradient(to right, rgba(255, 200, 40, 1), rgba(227, 29, 26, 1) 40%);
  border: 1px solid white;
}
.register-button.disabled {
  background: linear-gradient(to right, rgba(255, 200, 40, 0.5), rgba(227, 29, 26, 0.5) 40%);
  filter: brightness(80%); /* Reduce brightness by 50% */
  cursor: not-allowed; /* Change cursor to not-allowed */
}
.register-button.code-down {
  margin-bottom: -2.7vw;
}
.register-button:hover {
  cursor: pointer;
}
.register-button:active {
  transform: scale(0.99); /* Example of visual effect - scaling down the button upon click */
  /* Include other visual effects as per your design */
}
.text-laimon {
  text-decoration: underline;
  color: rgba(163, 212, 65, 1) !important;
}
.text-laimon:hover {
cursor: pointer;
}
.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 2.20vw;
  height: 2.20vw;
  background-color: #FFFFFF;
  border-radius: 1.44vw;
  margin-left: -3vw;
  margin-top: 0.65vw;
  margin-bottom: 1.3vw;
}
.popup-p-center p {
  font-family: Inter;
  font-size: 1.18vw;
  font-weight: 400;
  line-height: 1.46vw;
  width: 23.65vw;
  height: 2.27vw;
  margin: -3.5vw 0 1vw 3.1vw;
  color: rgba(255, 255, 255, 1);

}
.popup-p-center{
  margin-left: 3.13vw;
  margin-top: 1.39vw;
}
.popup-p-center.down{
  margin-left: 7.13vw;
  margin-top: 3.89vw;
  margin-bottom: -2vw;
}
.popup-p-center.down-login{
  margin-left: 5.13vw;
  margin-top: 4.89vw;
  margin-bottom: -2vw;
}
.custom-checkbox:before {
  content: '';
  display: inline-block;
  width: 1.46vw;
  height: 1.135vw;
  background-image: url("./image/vector.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.1s ease-in;
}
.custom-checkbox.error {
  border: 0.07vw solid red;
}

.input-checkbox:checked + .custom-checkbox:before {
  transform: translate(-50%, -50%) scale(1);
}


.controlledAccordions-div-faq {
  margin-top: 5vw;
}






#popup-content, #popup-content-banner {
  left: 50%;
  height: auto;
  border-radius: 2.08vw;
  padding: 1.6vw;
  width: 40.42vw;
  position: absolute;
  overflow: hidden;

  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#popup-content-soon{
  display: none;
}
.popup-content-code {
  background-color: rgba(0, 98, 65, 1);
  border-radius: 1.83vw;
  margin-top: 0;
  padding: 2.6vw 6.94vw;
  scrollbar-color: gray #d3d3d3;
  scrollbar-width: thin;
  width: 22.8vw;
}
.popup-content-code.success-reg-new {
  width: 71.697vw !important;
}
.success-reg-new-left {
  float: left;
  width: calc(50% - 1px);
  padding-right: 5px; /* Add space to separate the line */
  /* Other styles for the left part */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.success-reg-new-right {
  float: right;
  width: calc(50% - 1px);
  padding-left: 5px; /* Add space to separate the line */
  /* Other styles for the right part */
  box-sizing: border-box; /* Include padding in the width calculation */
  margin-top: -9.5vw;
  text-align: left;
}
.container-right {
  display: block;
  margin: 0vw 1.7vw 0vw 0vw;
  padding-top: 1vw;
}
.vertical-line-reg {
  border-left: 2px solid #A3D441;
  height: 23vw;
  box-sizing: border-box;
  position: relative;
  margin: -24vw 0vw 1vw 35.5vw;
  z-index: 55;
}
.horiz-line-reg {
  display: none;
}
.popup-content-code {
  height: 18.4vw;
}
.popup {
  background-color: rgba(0, 0, 0, .5);
  display: none;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity .5s ease-in-out;
  width: 100%;
  z-index: 9999;
}
.popup-soon {
  background-color: rgba(57, 99, 85, 0.35) !important;
  display: none;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity .5s ease-in-out;
  width: 100%;
  z-index: 9999;
}





























@media screen and (max-width: 767px) {
  .header {
    background-color: rgba(0, 98, 65, 1);
    width: 100%;
    height: 16.93vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #FFFFFF4D;
    width: 89.33vw;
    height: auto;
    min-height: 89vw;
    padding: 1.46vw;
    border-radius: 0.37vw;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  .styledsd {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #FFFFFF4D;
    width: 89.33vw !important;
    height: auto;
    min-height: 46vw;
    padding: 1.46vw;
    border-radius: 0.37vw;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  .textarea-sms {
    overflow: auto;
    min-width: 63vw;
    max-width: 88vw;
  }
    iframe {
      margin-top: 25vw;
    }
    .popupCode-input.error {
      border: 1px solid red;
      border-radius: 4vw;
    }
    .popupPassword-input.error {
      border: 1px solid red;
      border-radius: 4vw;
    }

    header .menu {
      display: none;
      flex-direction: column;
      position: fixed;
      height: 100%;
      width: 100%;
      right: 0;
      z-index: 50;
      overflow-y: auto;
      background-color: #FFFFFF;
      animation: burgerAnimation 0.4s;

    }
    header .main-menu{
      width: auto;
      position: absolute;
      flex-direction: column;
      row-gap: 7vw;
      margin-top: 6.52vw;
    }
    header .main-menu a {
      font-size: 2vw;
      padding: 0;
    }

    header .container {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      max-width: none;
      z-index: 5;
    }
    header .logo.transparent {
      opacity: 0.5; /* устанавливаем полностью прозрачный */
    }
    header .logo.visible  {
      opacity: 1; /* устанавливаем полностью непрозрачный */
    }
    header .logo{
      overflow: hidden;
      position: relative;
      z-index: 6;
      transition: opacity 0.5s ease; /* добавляем переходную анимацию прозрачности */
    }
    .main {
      background-color: #B1C728;
      max-width: none;
      width: 100%;
      height: 100%;
    }
    .prizes {
      max-width: none;
    }
    footer {
      max-width: none;
    }


    header .logo {
      position: absolute;
      width: 83.3vw;
      height: auto;
      margin-left: -5.7vw;
      margin-top: 2vw;
      z-index: 51;
    }

    header .logo-text {
      position: absolute;
      width: 32.3vw;
      height: auto;
      margin-left: -4.7vw;
      margin-top: 3vw;
      z-index: 51;
    }
    header .menu {
      flex-direction: column;
      position: fixed;
      height: 100%;
      width: 100%;
      right: 0;
      overflow-y: auto;
      background-color: #FFFFFF;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    header .menu.show {
      display: flex;
      position: fixed;
      flex-direction: column;
      height: 200vh;
      width: 100vw;
      right: 0;
      overflow-y: auto;
      background-image: linear-gradient(#00622A, #009640, #03BE53);
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    html.menu-open,
    body.menu-open {
      overflow: hidden;
    }


    header .menu.show .logo-content.logo-text {
      display: block;
      text-align: center;
      font-size: 2.5vw;
      color: white;
      margin-top: 2vw;

    }
    .error-page-block {
    }

    .menu .backFaq {
      display: none;
    }



    header .main-menu a {
      font-size: 5.87vw;
      line-height: 6.40vw;
      padding: 0;
      margin-left:8vw;
    }

    header .main-menu.backFaq a {
      font-size: 9.87vw;
      line-height: 6.40vw;
      padding: 0;
    }

    .button-animation {
      line-height: 4.53vw;
      text-decoration: none;
      cursor: pointer;
      text-transform: uppercase;
      border: none;
      background-color: transparent;
      margin-top: -1.2vw;
      font-size: 4.27vw;
      height: 1.1vw;
      font-family: 'Acrom Medium', Arial, sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      margin-left: 1.5vw;
    }
  .left-photo {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 26.62vw;
    height: auto;
    margin-top: 3.78vw;
    margin-left: -10.31vw;
    z-index: 2;
  }
  .svg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 91.32vw;
    height: 91.32vw;
    margin-top: -28.5vw;
    margin-left: -43.15vw;
    z-index: 1;
  }
  .logo img {
    margin-left: -7.3vw;
    width: 74%;
  }

    .header-burger {
      display: flex;
      width: 13vw;
      height: 13vw;
      right: 0;
      margin-left: 83.5vw;
      margin-top: 1.2vw;
      cursor: pointer;
      position: relative;
      z-index: 9999;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      transition: transform 0.3s ease;
    }
    .header-burger.active {
      transform: rotate(90deg);
    }

    .header-burger span {
      height: 0.7vw;
      margin-top: -4vw;
      width: 10vw;
      transform: scale(1);
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.5vw;
    }

    .header-burger::before,
    .header-burger::after {
      content: "";
      position: absolute;
      height: 0.7vw;
      width: 10vw;
      margin-top: 3.2vw;
      background-color: rgba(255, 255, 255, 1);
      transition: all 0.3s ease 0s;
      border-radius: 0.5vw;
      z-index: 1;
    }

    .header-burger::before {
      top: calc(50% - 1.4vw);
    }

    .header-burger::after {
      bottom: calc(50% - 0.4vw);
    }

    .header-burger.active span {
      transform: scale(0);
    }

    .header-burger.active::before {
      width: 10vw;
      margin: 1.35vw 0vw 0vw 0vw;
      transform: rotate(-45deg) translate(0, -50%);
      background-color: rgba(255, 255, 255, 1);
    }

    .header-burger.active::after {
      width: 10vw;
      margin: 0vw 0vw 0.35vw 0vw;
      transform: rotate(45deg) translate(0, 50%);
      background-color: rgba(255, 255, 255, 1);
    }


    .main {
      padding: 50.5vw 0;
      background-color: rgba(162, 212, 65, 1);
      background-size: cover;
      width: 100%;
      height: 182vw;
      font-family: 'Circe', sans-serif;
      font-weight: 700;
      scroll-behavior: smooth;
      font-size: 0.83vw;
      line-height: 1.5;
    }


    .main-text {
      display: none;
    }

    .button-animation-text {
      display: none;
    }
  .winners-text {
    z-index: 5;
    width: 65vw;
    margin-top: -42.8vw;
    margin-left: 12.6vw;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10.13vw;
    line-height: 9.6vw;
    color: #EEF3EC;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    text-align: center;
    color: rgba(0, 98, 65, 1);
  }
  .bottle-float-left {
    position: absolute;
    margin-top: -18.65vw;
    margin-left: -78.17vw;
    width: 80.5%;
    height: auto;
    z-index: 9;
  }
  .bottle-float-left.nature {
    position: absolute;
    margin-top: -17.47vw;
    margin-left: -21.97vw;
    width: 20.9%;
    height: auto;
    z-index: 4;
    animation: sbs-23142-552431 2s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.lefttree {
    position: absolute;
    margin-top: -12.4vw;
    margin-left: -82.47vw;
    width: 22%;
    height: auto;
    z-index: 4;
    animation: sbs-23142 25s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.lefttree-mobile {
    display: none;
  }
  .bottle-float-left.lefttree-mobile.bon1 {
    display: flex;
    position: absolute;
    margin-top: 6.6vw;
    margin-left: 0.53vw;
    width: 29vw;
    height: auto;
    z-index: 10;
    animation: sbs-231425214 25s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.two {
    position: absolute;
    margin-top: 11.75vw;
    margin-left: -47.17vw;
    width: 94.5%;
    height: auto;
    z-index: 4;
    right: 0;
  }
  .bottle-float-left.uptree {
    display: none;
    position: absolute;
    margin-top: 12.63vw;
    margin-left: -7.57vw;
    width: 4.3%;
    height: 1.38%;
    z-index: 4;
    animation: sbs-23142 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.inputcode {
    position: absolute;
    margin-top: 111.03vw;
    margin-left: -72.57vw;
    width: 46.81vw;
    height: 14.27vw;
    z-index: 6;
  }
  input.new-code {
    font-family: Inter;
    width: 57.56vw;
    height: 14.1vw;
    border: 0.14vw solid red;
    border-radius: 6.68vw;
    padding: 0.35vw;
    font-size: 1.11vw;
    margin-top: 111.1vw;
    margin-left: -40.2vw;
    text-align: center;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    z-index: 5;
    position: absolute;
  }
  input.new-code-profile {
    font-family: Inter;
    width: 57.56vw;
    height: 14.1vw;
    border: 0.14vw solid red;
    border-radius: 6.68vw;
    padding: 0.35vw;
    font-size: 1.11vw;
    margin-top: 111.1vw;
    margin-left: -40.2vw;
    text-align: center;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    z-index: 5;
    position: absolute;
  }
  .text-inputcode {
    position: absolute;
    width: 62vw;
    margin: 121.8vw 0vw 0vw -30vw;
    font-family: Inter;
    font-weight: 400;
    font-size: 3.47vw;
    line-height: 3.27vw;
    text-transform: none;
  }


  .products {
    display: flex;
    height: 155vw;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(248, 214, 72, 1);
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-bottom: 5vw;
    width: 95vw;
  }
  .winners-text.for-slider {
    z-index: 5;
    width: 68vw;
    font-size: 10.47vw;
    text-transform: none;
    text-align: center;
    margin: 17vw auto;
    margin-top: 14.2vw;
    margin-left: 16vw;
    color: rgba(0, 98, 65, 1);
    line-height: 1;
  }
  .slider-container {
    z-index: 5;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    position: relative;
    max-width: 80.88vw;
    margin: 0 auto;
    text-align: center;
    margin-top: -9.7vw;
    margin-left: 4.7vw;
  }
  #wave-container-test {
    width: 100%;
    height: 5.21vw;
    overflow: hidden;
    background: rgba(248, 214, 72, 1);
  }
  .bottle-float-left.lime {
    position: absolute;
    margin-top: -20.47vw;
    margin-left: -15.2vw;
    width: 18.6vw;
    height: 22.6vw;
    z-index: 4;
    right: 0;
  }
  #wave-test {
    display: block;
    position: relative;
    height: 2.08vw;
    background: #a3d441;
  }
  #wave-test:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 15.63vw;
    background: rgba(248, 214, 72, 1);
    right: -25%;
    top: 1.04vw;
  }
  #wave-test:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 15.63vw;
    background: #a3d441;
    left: -25%;
    top: -12.50vw;
  }
  .how-full4-img img, .how-full44-img img, .how-full444-img img, .how-full4444-img img {
    width: 269%;
    transition: transform 0.5s ease;
    position: relative;
  }
  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 15vw !important;
    line-height: 1 !important;
    margin-left: -4vw;
    opacity: 1 !important;
    color: white !important;
    background-color: transparent !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev, .slick-next {
    background-color: transparent !important;
    color: orange !important;
    width: 2.08vw !important;
    height: 2.08vw !important;
    position: absolute !important;
    top: 33% !important;
    transform: translateY(-50%);
    z-index: 5 !important;
    background-color: #fff;
    padding: 0.26vw !important;
    cursor: pointer;
  }
  .slick-list {
    margin-left: 4vw !important;
  }
  .lf-text {
    margin: 7.5vw 0vw 0vw 15.3vw;
    display: block;
    position: relative;
    width: 50vw;
    height: 20vw;
    font-family: Inter;
    font-weight: 600;
    font-size: 6.67vw;
    line-height: 6.67vw;
    color: rgba(0, 98, 65, 1);
  }
  /*html, body {*/
  /*  background-color: white;*/
  /*  height: 100%;*/
  /*  margin: 0;*/
  /*  margin: 0;*/
  /*  padding: 0;*/
  /*}*/
  /*body {*/
  /*  background-size: cover;*/
  /*  font-size: 1.11vw;*/
  /*  height: auto;*/
  /*  line-height: 1.5;*/
  /*  margin: 0;*/
  /*  min-width: 0;*/
  /*  min-width: auto;*/
  /*  padding: 0;*/
  /*  scroll-behavior: smooth;*/
  /*}*/
  /*.container {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  position: relative;*/
  /*  height: auto;*/
  /*  width: 100%;*/
  /*}*/

  * {
    /*margin: 0;*/
    /*padding: 0;*/
    box-sizing: border-box;
  }
  .bottle-float-left.down-tree-prod {
    display: none;
    position: absolute;
    margin-top: 38.973vw;
    margin-left: -47.97vw;
    width: 7.3%;
    height: 1.968%;
    z-index: 4;
    animation: sbs-23142 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.top-tree-prod {
    display: none;
  }
  .bottle-float-left.uptree-prod {
    display: none;
  }
  .bottle-float-left.gps {
    display: none;
  }
  .absolute-text {
    display: none;
  }
  .bottle-float-left.gps-down-product {
    display: none;
  }
  .bottle-float-left.gps-top-product {
    display: none;
  }




  .prizes-text {
    margin: 3vw auto;
    margin-top: 13.8vw;
    margin-left: 1.2vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
    color: #EEF3EC;
    font-size: 10.13vw;
    line-height: 9.60vw;
    width: 86.75vw;
    height: 8.05vw;
  }
  .center-container {
    height: 152.5vw;
    margin: 15vw 1.67vw 0 9vw;
    width: 68vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(245, 245, 237, 0.7);
    border-radius: 9.43vw;
  }
  .main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sub-div {
    text-align: center;
    margin: 1rem 0;
  }

  .vertical-line {
    display: none;
    border-left: 2px solid #000; /* или другой цвет, толщину и стиль линии, как вам нужно */
  }
  .for-faq.faq {
    margin-top: 12.8vw;
  }

  .how {
    background-size: cover;
    height: 193vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    min-width: initial;
    scroll-behavior: smooth;
    font-size: 0.83vw;
    line-height: 1.5;
    margin-top: 0;
    padding-top: 1.46vw;
    background-color: rgba(91, 171, 113, 1);
  }
  .sub-div img {
    margin: -14vw 0vw 2vw -1vw;
    max-width: 28vw;
    height: auto;
    border-radius: 50%;
  }
  .sub-div-text {
    margin-left: -14vw !important;
    width: 53.96vw;
    height: 8vw;
    margin-top: 0vw !important;
    margin-bottom: 9vw !important;
    font-family: Inter;
    font-weight: 700;
    font-size: 4vw;
    line-height: 4.80vw;
    color: rgba(0, 98, 65, 1);
  }
  .sub-div p {
    margin: 1.1vw 0vw 0vw 3.5vw;
  }


  .how-prizes {
    background-size: cover;
    height: 339vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    min-width: initial;
    scroll-behavior: smooth;
    font-size: 0.83vw;
    line-height: 1.5;
    margin-top: 0;
    padding-top: 1.46vw;
    background-color: rgba(91, 171, 113, 1);
  }
  .bottle-float-left.twotree {
    display: none;
  }
  .bottle-float-left.nv {
    display: none;
  }
  .bottle-float-left.berry {
    position: absolute;
    margin-top: -38.45vw;
    margin-left: 13.33vw;
    width: 24.06vw;
    height: 24.26vw;
    z-index: 4;
    animation: sbs-231425214-52151 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.top-tree-how {
    display: none;
  }
  .bottle-float-left.lefttree-how {
    display: none;
  }
  .bottle-float-left.gps-sochi {
    display: none;
  }
  .bottle-float-left.gps-chel {
    display: none;
  }
  .prizes-text-next {
    margin: 27vw auto;
    margin-top: 10.8vw;
    margin-left: 4.78vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
    color: #EEF3EC;
    font-size: 10vw;
    line-height: 9.6vw;
    width: 82.75vw;
    height: 8.05vw;
  }
  .prizes-content {
    height: 140.5vw;
    width: 68vw;
    margin: 21vw 1.67vw 0 9vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9.43vw;
  }
  .images {
    flex-direction: column;
    display: flex;
  }
  .images-phone {
    flex-direction: column;
    display: flex;
  }
  .images-phone img {
    margin-left: -3vw;
    margin-right: 0vw;
    width: 48.75vw;
    height: 8.75vw;
    height: auto;
  }
  .million {
    margin: -52.7vw 0vw 3vw 6.9vw;
    width: 72.88vw;
    height: auto;
  }
  .wrapper-how-prizes {
    width: 92.6vw;
    margin-left: 4vw;
    overflow: hidden;
  }
  .right-p-name {
    margin: 80.55vw 0vw 5vw 1.4vw;
    font-family: Inter;
    font-weight: 700;
    font-size: 6.67vw;
    line-height: 6.57vw;
    text-transform: uppercase;
    color: white;
  }
  .images img {
    margin-left: 3vw;
    margin-right: 0vw;
    width: 48.75vw;
    height: 8.75vw;
    height: auto;
  }
  .right-p-name.left-down {
    margin: 2.55vw 1vw 1vw 7.6vw;
  }
  .left-p-name-low {
    margin: 0.6vw 0vw 18vw 12.1vw;
    width: 61.97vw;
    height: 5vw;
    font-size: 4.27vw;
    line-height: 4.53vw;
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-weight: 500;
  }

  .image-container {
    text-align: center;
  }
  .image-container.enable {
    display: none;
  }
  .image-container-for-phone {
    position: absolute;
    display: flex;
    margin: 5.3vw 0 0 23vw;
  }
  .image-container-for-phone.line {
    position: absolute;
    display: flex;
    margin: 63vw 0 0 24vw;
  }
  .images-phone-line img {
    margin-left: 3vw;
    margin-right: 0vw;
    width: 34.75vw;
    height: 8.75vw;
    height: auto;
  }


  .new-block {
    /* стили для блока моментальных призов */
  }
  .left {
    display: flex;
    flex-direction: column;
  }
  .right {
    left: 0;
    width: 87vw;
    position: absolute;
    margin: 107vw 0vw 0vw 6vw;
}

  .image-container p {
    margin: 1.19vw -3vw 4vw 0.4vw;
  }
  .right-p-prizes {
    width: 54vw;
    font-family: Inter;
    font-weight: 600;
    font-size: 5.87vw;
    line-height: 6.13vw;
    color: white;
  }
  .right-p-prizes-little {
    width: 54vw;
    margin-left: 0.5vw !important;
    font-family: Inter;
    font-weight: 300;
    font-size: 4.18vw;
    line-height: 4.65vw;
    color: white;
  }
  .right-p-name.down {
    margin: 64vw 0vw 5vw 4vw;
  }
  .container-sub {
    /*margin-top: 13vw;*/
    /*margin-left: 2vw;*/
    width: 84.69vw;
    height: 33.35vw;
  }
  .slick-slider.prizesShow {
    width: 70.57vw;
    height: 15.17vw;
    margin-left: 8.5vw;
    margin-top: -1vw;
    margin-bottom: -3vw;
  }
  .prizesShow .slick-prev {
    margin-left: -10.3vw !important;
    margin-top: 10vw;
  }
  .prizesShow .slick-next {
    margin-right: 0vw !important;
    margin-top: 11vw !important;
  }
  .new-block {
    background-color: rgba(140, 196, 155, 1);
    margin: -1vw 0vw 0vw 19.7vw;
    width: 48.72vw;
    height: 7vw;
    border-radius: 23.47vw;
  }
  .new-block {
    display: none;
  }
  .winners {
    height: 214.5vw;
    width: 100%;
    margin-top: -6.1vw;
    padding-top: 0.56vw;
    background-color: rgba(91, 171, 113, 1);
  }
  .winners-text.winners-main {
    z-index: 5;
    width: 79.47vw;
    margin-top: 5.5vw;
    margin-left: 10.4vw;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10.13vw;
    line-height: 9.60vw;
    color: #EEF3EC;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
  .winners-text.second {
    position: relative;
    z-index: 2;
    margin: 14vw 0vw 8vw 16vw;
  }
  .bottle-float-left.berry-prizes {
    z-index: 1;
    position: absolute;
    margin-top: -264.05vw;
    margin-left: -29.07vw;
    width: 15.06vw;
    height: auto;
    transform: scaleX(-1);
    animation: sbs-23142-left 25s infinite linear;
    backface-visibility: hidden;
  }
  .table {
    border-radius: 0.520833333333333vw;
    padding: 1.041666666666667vw 0 1.5625vw;
    width: 77.8125vw;
    min-height: 20vw;
  }
  .table-body {
    color: #eef3ec;
    font: 500 1.041666666666667vw 'Montserrat', sans-serif;
    line-height: 1.041666666666667vw;
    margin-left: 2.2vw;
    margin-top: 6.7vw;
  }
  .table-body-winners {
    position: relative;
    z-index: 5;
    background-color: rgba(245, 245, 237, 0.7);
    border: .07vw solid #ffffff4d;
    border-radius: 3.04vw 3.04vw 1.35vw 3.04vw;
    display: flex;
    flex-flow: row nowrap;
    font-family: Halvar Mittelschrift Rg, sans-serif;
    font-size: 1.17vw;
    font-weight: 400;
    height: 22.23vw;
    justify-content: flex-start;
    margin-top: 0.652083vw;
    margin-bottom: 4vw;
    text-align: center;
    width: 91.35vw;
  }
  .head-colm-prize1 {
    position: absolute;
    font-size: 5.07vw;
    line-height: 8vw;
    font-weight: 600;
    font-family: Inter;
    color: rgba(0, 98, 65, 1);
    width: 15.427083vw;
    margin-top: 2.7vw;
    margin-left: -2vw;
  }
  .winners-block-img {
    display: inline-block;
    background-color: rgba(245, 245, 237, 1);
    width: 16.56vw;
    height: 16.56vw;
    margin: 1vw 4vw 0vw -6vw;
    border-radius: 3.69vw;
  }
  .winners-prize-img {
    height: auto;
    margin: 3vw 3vw 0;
    width: 10.56vw;
  }
  header .menu.show {
    background-image: linear-gradient(#284031, #266a41, #03be53);
    display: flex;
    opacity: 1;
    width: 100vw;
  }
  img.bigline-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999; /* число можно выбрать любое большое */
  }
  header .main-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    row-gap: 8.6vw;
    margin: 68vw 0vw 0vw -5vw;
    height: 111vh;
  }
  .jss5 {
    width: 96% !important;
    margin: 0px 0px 25px 1px !important;
    border-top: 2px solid #ccc;
    background-color: #fff;
  }
  .main-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
  }
  .main-menu li {
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    line-height: 1.3vw;
    font-size: 1.11vw;
    font-weight: 400;
    margin-right: -3.9vw;
    margin-top: 9.66vw;
    padding: 0.5vw 1vw;
    color: #fff;
    letter-spacing: 0.2vw;
  }
  .main-menu .li-rules {
    margin-right: -1vw;
  }
  .main-menu .li-ont {
    margin-right: 0vw;
  }
  .main-menu .li-lc {
    margin-left: -1.4vw;
  }

  .head-colm-name {
    color: #006241;
    font-family: Inter;
    font-size: 4.57vw;
    font-weight: 600;
    letter-spacing: .3vw;
    line-height: 4.5vw;
    margin-left: 11vw;
    margin-top: 4.4vw;
    position: absolute;
    text-align: left;
    width: 61.427083vw;
  }
  .head-colm-phone1 {
    font-size: 4.27vw;
    line-height: 5.07vw;
    letter-spacing: 0.07vw;
    font-weight: 500;
    font-family: Inter;
    color: rgba(0, 98, 65, 1);
    min-width: 17.364583333333332vw;
    margin-top: 1.75vw;
    margin-left: 1.1vw;
  }
  .head-colm-phone1 {
    position: absolute;
    min-width: 0.364583vw;
    margin-top: 13.7vw;
    padding-left: 10vw;
  }
  .head-colm-date1-phone {
    display: flex;
    min-width: 8.364583vw;
    margin-top: 1.65vw;
    margin-left: auto;
  }
  .head-colm-date1 {
    min-width: 8.364583vw;
    margin-top: 1.65vw;
    margin-left: auto;
  }
  .winners-all-a.winners-test-a {
    z-index: 2;
    position: relative;
    display: block;
    width: 91.35vw;
    font-size: 6.27vw;
    line-height: 6.40vw;
    font-family: Inter;
    margin-top: -2vw;
    font-weight: 600;
    color: rgba(245, 245, 237, 1);
    text-decoration: none;
    margin: 0 auto;
    background-color: rgba(0, 98, 65, 1);
    border: .07vw solid #ffffff4d;
    padding: 4.5vw 26vw 4.5vw 26vw;
    border-radius: 4.04vw 4.04vw 1.35vw 4.04vw;
    transition: all 0.3s ease;
  }
  .for-faq {
    width: 80vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    margin-top: -31.8vw;
    margin-left: 9.6vw;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10.13vw;
    line-height: 9.6vw;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 4vw;
    color: rgba(0, 98, 65, 1);
  }
  .bottle-float-left.example-tree5.main-down {
    display: none;
  }
  .bottle-float-left.example-tree5.main-down-town {
    display: flex;
    z-index: 4;
    margin-top: -13.95vw;
    margin-left: 20.13vw;
    width: 31.01vw;
    height: auto;
    animation: sbs-231425214-52151 25s infinite linear;
    backface-visibility: hidden;

  }







  .accordion-example {
    position: relative;
    z-index: 1;
    padding-top: 50vw;
    padding-bottom: 0vw;
    /* overflow: hidden; */
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #a3d441;
    background-size: cover;
    width: 100%;
    font-size: 0.83vw;
  }
  .MuiAccordion-rounded {
    background-color: #fff;
    border: 1px solid #286043 !important;
    border-radius: 2.8vw !important;
    color: #fff !important;
    margin-bottom: 3vw;
    transition: background-color .3s !important;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 2.72vw !important;
    margin-top: 1vw !important;
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
  .bottle-float-left.left-line-how {
    display: none;
  }
  .heading-id {
    font-weight: 500;
    font-family: Inter;
    margin-left: 2.5vw;
    font-size: 8.53vw;
    line-height: 10.40vw;
  }
  .heading-name {
    width: 66vw;
    margin-left: 3vw;
    margin-top: -7.5vw;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 5.07vw;
    line-height: 6.13vw;
    text-indent: 2.7em;
  }
  .MuiTypography-body1 {
    font-size: 3.47vw !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400 !important;
    line-height: 4.27vw !important;
    letter-spacing: 0.00938em;
    text-align: left !important;
  }
  .winners-all-a.faq {
    z-index: 2;
    position: relative;
    display: block;
    font-size: 5.17vw;
    line-height: 6.40vw;
    font-family: Inter;
    font-weight: 600;
    color: rgba(245, 245, 237, 1);
    text-decoration: none;
    margin: 0 auto;
    background-color: rgba(0, 98, 65, 1);
    border: .07vw solid #ffffff4d;
    width: 86.35vw;
    padding: 4.5vw 26vw 4.5vw 26vw;
    border-radius: 4.04vw 4.04vw 1.35vw 4.04vw;
    transition: all 0.3s ease;
  }
  .footer {
    display: none;
  }
  .left-footer {
    font-family: Inter;
    font-weight: 400;
    font-size: 4.80vw;
    line-height: 5.87vw;
    color: rgba(245, 245, 237, 1);
    width: 94.06vw;
    text-align: left;
    margin-right: auto;
    margin-left: 6vw;
    margin-top: 5vw;
    margin-bottom: 2vw;
  }
  .right-footer {
    margin: -12.7vw 23vw 0vw 0vw;
    font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
    font-weight: 400;
    font-size: 10.13vw;
    line-height: 9.87vw;
    color: rgba(245, 245, 237, 1);
    width: 100%;
    text-align: center;
    margin-left: auto;
  }
  .buttons {
    margin-top: -8vw;
    padding-bottom: 5vw;
  }
  .footer-left-button {
    margin: 9vw 0vw 0vw -47vw;
    position: absolute;
    font-size: 3.25vw;
    font-family: Inter;
    line-height: 4.53vw;
    font-weight: 400;
    width: 53.57vw;
    height: 13.6vw;
    border-radius: 4.97vw;
    color: white;
    background-color: rgba(0, 98, 65, 1);
    border: 1px solid white;
  }
  .footer-right-button {
    position: absolute;
    margin: 9vw 0vw 0vw 10vw;
    font-size: 3.47vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 4.53vw;
    width: 37.57vw;
    height: 13.58vw;
    border-radius: 4.97vw;
    background-color: rgba(162, 212, 65, 1);
    border: 1px;
  }
  .content-container-footer {
    background-color: rgba(0, 98, 65, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 87vw;
  }
  .bottle-float-left.show {
    display: flex;
    position: absolute;
    margin-top: 0.03vw;
    margin-left: -14.57vw;
    width: 48vw;
    height: 14vw;
    z-index: 6;
  }
  .shadow-button-animation-text {
    display: flex;
    font-family: Inter;
    width: 76.56vw;
    height: 14.1vw;
    background-color: white;
    border: 0.14vw solid red;
    border-radius: 6.68vw;
    padding: 0.35vw;
    font-size: 1.11vw;
    text-align: center;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    z-index: 5;
    position: absolute;
  }
  .shadow-dont {
    display: flex;
  }
  .faq-question {
    margin-left: 1vw;
    /*text-decoration: underline;*/
    color: rgba(0, 98, 65, 1);
    font-family: Inter;
    font-weight: 600;
    font-size: 4.80vw;
    line-height: 4.60vw;
    margin-bottom: 21vw;
  }
  .faq-question-p {
    font-size: 4.80vw;
    line-height: 5.6vw;
    margin-top: 5vw;

  }
  .winners-main.center {
    text-align: center;
    margin-top: 20vw;
    margin-left: 9.8vw;
    margin-bottom: 9.9vw;
  }
  .winners-main.left {
    margin-left: 10.4vw;
  }
  .slick-slider.winnersSearch {
    width: 45.57vw;
    height: 10.17vw;
    margin-left: 24.5vw;
    margin-top: 7vw;
  }
  .slick-prev.winnersSearch {
    margin-left: 55vw !important;
  }
  .slick-slider.winnersSearchCodes {
    width: 22.57vw;
    height: 4.17vw;
    margin-left: 0.5vw;
    margin-top: 3vw;
  }
  .winnersSearch .slick-list {
    margin-left: 7vw !important;
  }


  .winnersSearch .slick-prev::before
  {
    margin-left: -13vw !important;
    margin-top: 3vw !important;
  }
  .winnersSearch .slick-next::before
  {
    margin-left: 1vw !important;
    margin-top: 3vw !important;
  }
  .slider-search-winnersall {
    padding: 0.89vw 0.6vw 0.89vw 0.6vw;
    margin: 3vw 0vw 0vw 0vw;
    border-radius: 1.04vw;
    color: white;
    font-family: 'Acrom Medium', AutumnSweaterLaimon, sans-serif;
    font-weight: 400;
    font-size: 5.15vw;
    line-height: 9.71vw;
    background-color: rgba(0, 98, 65, 1);
  }
  .scroll-container {
    position: relative;
    overflow: hidden;
    width: 94.24vw;
    height: 80vw;
  }
  .custom-simple-bar2 .simplebar-track.simplebar-vertical {
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    left: 0;
    margin-left: 92.6vw;
    margin-top: 0.75vw;
    width: 0.44vw !important;
    height: auto;
    opacity: 1 !important;
    border-radius: 50px;
    z-index: 2;
    overflow: visible;
  }
  .bottle-float-left.example-tree6.winners {
    position: absolute;
    margin-top: 11.05vw;
    margin-left: -46.67vw;
    width: 12vw;
    height: auto;
    z-index: 1;
    animation: sbs-231425214 15s infinite linear;
    backface-visibility: hidden;
  }
  .winners-text.second-second {
    text-align: center;
    margin: 6vw 0 2.2vw 6.1vw;
  }
  .bottle-float-left.example-tree5.faq-down-down {
    z-index: 4;
    margin-top: -17.95vw;
    margin-left: -38.87vw;
    width: 25.01vw;
    height: auto;
  }
  .bottle-float-left.lime-winners {
    position: absolute;
    margin-top: -59.17vw;
    margin-left: -2.97vw;
    right: 0;
    width: 8vw;
    height: 17vw;
    z-index: 4;
  }
  .bottle-float-left.berry-winners {
    position: absolute;
    margin-top: -17.75vw;
    margin-left: 32.93vw;
    width: 12.06vw;
    height: 12.26vw;
    transform: scaleX(-1);
    animation: sbs-23142-left 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.example-tree5.winners {
    width: 10vw;
    margin-top: -13.95vw;
    margin-left: 26.33vw;
    transform: rotate(-104deg);
    height: auto;
    animation: sbs-231425214-52151 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.example-tree5 {
    position: absolute;
    margin-top: -56.95vw;
    margin-left: 16.33vw;
    width: 22.53vw;
    height: auto;
    z-index: 4;
    animation: sbs-231425214 25s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.example-tree5-5 {
    position: absolute;
    margin-top: -13.95vw;
    margin-left: 24.33vw;
    width: 11.53vw;
    height: auto;
    z-index: 4;
    animation: sbs-231425214 25s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.example-tree6 {
    position: absolute;
    margin-top: 18.05vw;
    margin-left: -48.67vw;
    width: 9.47vw;
    height: auto;
    z-index: 0;
    animation: sbs-231425214 15s infinite linear;
    backface-visibility: hidden;
  }
  .bottle-float-left.example-tree5.faq-down {
    z-index: 5;
    margin-top: -20.95vw;
    margin-left: -45.87vw;
    width: 24.01vw;
    height: auto;
  }
  .bottle-float-left.example-tree5.faq-down-leetree {
    display: flex;
    z-index: 5;
    margin-top: -20.95vw;
    margin-left: 0.13vw;
    width: 19.01vw;
    height: auto;
  }
  .bottle-float-left.lime-faq {
    position: absolute;
    margin-top: -46.47vw;
    margin-left: -2.97vw;
    right: 0;
    width: 9vw;
    height: auto;
    z-index: 4;
  }
  .popup {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  #popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  #popup-content {
    /*background-color: #FFFFFF4D;*/
    width: 73.69vw;
    height: auto;
    padding: 2.6vw 6.94vw;
    border-radius: 1.83vw;
    margin-top: 1vw;
    /*scrollbar-width: thin;*/
    /*scrollbar-color: gray lightgray;*/
  }
  #popup-content-soon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
  #popup-content-soon {
    /* background-color: #FFFFFF4D; */
    width: 73.69vw;
    height: auto;
    padding: 2.6vw 6.94vw;
    border-radius: 4.83vw;
    margin-top: 1vw;
    /* scrollbar-width: thin; */
    /* scrollbar-color: gray lightgray; */
  }



  .register-inputs {
    border: 1px white solid;
    color: rgba(0, 98, 65, 1);
    font-family: Inter;
    font-size: 4.18vw;
    line-height: 4.46vw;
    width: 58.31vw;
    height: 9.17vw;
    border-radius: 3.04vw;
    background-color: rgba(255, 255, 255, 1);
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
  .register-inputs-text {
    text-align: left;
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-weight: 400;
    font-size: 3.18vw;
    line-height: 3.46vw;
    margin-bottom: 0.5vw;
    margin-left: 2vw;
  }
  .register-main-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: rgba(163, 212, 65, 1);
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 6.78vw;
    font-weight: 400;
    margin-top: 4vw;
    margin-left: -3vw;
    line-height: 6.61vw;
  }
  .bottle-float-left.exit-register {
    right: 0;
    padding-right: 1.7vw;
    margin: -9.2vw 0vw 0vw 35vw;
    position: absolute;
    width: 10.4vw;
    height: auto;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .register-main-text.soon {
    width: 53vw;
    margin: 7vw 0vw 0vw 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: rgba(163, 212, 65, 1);
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 6.78vw;
    font-weight: 400;
    line-height: 6.61vw;
  }
  .register-main-text.soon-white {
    width: 62vw;
    margin: 6vw 0vw 7vw -2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: rgba(255, 255, 255, 1);
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 11.52vw;
    font-weight: 400;
    line-height: 6.61vw;
  }
  .bottle-float-left.email-remember {
    margin: -15.2vw 0vw 0vw 35vw;
  }
  .bottle-float-left.success-email {
    margin: -20.2vw 0vw 0vw 35vw;
  }
  .bottle-float-left.success-email-new {
    margin: -20.2vw 0vw 0vw 35vw;
  }
  .register-button {
    font-size: 4.74vw;
    font-family: Inter;
    line-height: 4.67vw;
    font-weight: 600;
    width: 56.08vw;
    height: 11.07vw;
    border-radius: 3.68vw;
    color: white;
    background: linear-gradient(to right, rgba(255, 200, 40, 1), rgba(227, 29, 26, 1) 40%);
    border: 1px solid white;
  }
  .bottle-float-left.success-image {
    padding-right: 0.7vw;
    margin: -18.2vw 0vw 0vw -8.6vw;
    position: absolute;
    width: 15.81vw;
    height: auto;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .register-main-text.top-margin {
    margin-top: 19.5vw;
  }
  .popup-p-center p {
    font-family: Inter;
    font-size: 3.18vw;
    font-weight: 400;
    line-height: 3.46vw;
    width: 44.65vw;
    height: 18.27vw;
    margin: -1.5vw 0 -9vw 2.1vw;
    color: rgba(255, 255, 255, 1);
  }
  .register-inputs-text.login {
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-weight: 400;
    font-size: 4.18vw;
    line-height: 4.46vw;
    margin-bottom: -2.5vw;
    margin-left: 7.2vw;
    margin-top: 7vw;
    width: 60vw;
    text-align: center;
  }
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #FFFFFF4D;
    width: 73.69vw !important;
    height: auto;
    min-height: 89vw;
    padding: 2.46vw 6vw;
    border-radius: 1.83vw;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  .content-container-modal {
    margin-left: 5vw;
  }
  .container-register-modal {
    padding: 0vw 5.5vw;
  }
  .custom-checkbox {
    position: relative;
    display: inline-block;
    width: 6.2vw;
    height: 6.2vw;
    background-color: #FFFFFF;
    border-radius: 3.44vw;
    margin-left: -3vw;
    margin-top: 3.65vw;
    margin-bottom: 1.3vw;
  }
  .custom-checkbox:before {
    margin-top: 0.5vw;
    margin-left: 0.1vw;
    content: '';
    display: inline-block;
    width: 3.46vw;
    height: 3.135vw;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAbCAYAAAAULC3gAAABDUlEQVR4Xu3VvQ2CUBDAcUZgBMMCMgIjWLqFbiAb2IsNMziAjXYkVNraamLyRjjvKRfNceID3kfjP7nEROD94iUSRf9Ym0kaFUn9HP05aC+MwoFmFL/EX21MOFC2m2cCBmey4tc6CwBinPxwq+q4nDKIJ0yDmOHscaC+nyAIBs/OcNY4SkOCYJpfI9dnE4LyhgG2EikvGBBWIuUUAx0rkXKCAYOVSI3DCO8SMFyJ1DiMbptcPm5Ux2tltBKp8Rgd+wuPyxQffOZn/cwORlckS/6Qvih7GErfOBBlH0MNQLnDUD1Q7jGUAcofhupA+cdQX1BhMJSAao8vDNWJ8o2hRFQoDPVGKXwJL/jXtnoAhFi9qWoMdaEAAAAASUVORK5CYII=);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.1s ease-in;
  }
  .p-span-1 p {
    margin: -8vw 0vw -11vw 9vw;
  }
  .p-span p {
    margin: -8vw 0vw -9vw 9vw;
  }
  .error-block-phone {
    position: absolute;
    color: #ffaeae !important;
    margin: 0.5vw 1vw 0vw 2vw;
    font-size: 2.1vw;
  }
  .error-block-phone.test-register {
    position: absolute;
    color: #ffaeae !important;
    margin: -0.7vw 1vw 0vw 2vw;
    font-size: 2.5vw;
  }

  .register-inputs-text.code-left {
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-weight: 400;
    font-size: 4.18vw;
    line-height: 4.46vw;
    margin-bottom: 3.5vw;
    margin-left: 1.2vw;
    margin-top: 7vw;
    width: 60vw;
    text-align: center;
  }
  .register-inputs-text.code-left-email {
    width: 62vw;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 5vw;
    margin-left: -1vw;
  }
  .register-inputs.code-bottom {
    margin-bottom: 2.8vw;
    margin-left: 2vw;
  }
  .register-button.code-down {
    margin-top: 3vw;
    margin-bottom: -0.7vw;
  }
  .error-block-phone.test-code-add {
    position: absolute;
    color: #ffaeae !important;
    margin: -1.8vw 1vw 0vw 3vw;
    font-size: 2vw;
  }
  .bottle-float-left.bigline-mobile {
    display: block;
    position: absolute;
    margin-top: -34.65vw;
    margin-left: -78.17vw;
    width: 80.5%;
    height: auto;
    z-index: 5;
  }
  .bottle-float-left-mobile {
    display: flex;
    position: absolute !important;
    margin-top: -1010.65vw;
    right: 0;
    /* margin-left: -1.17vw; */
    width: 99.27vw !important;
    height: 1250.47vw !important;
    z-index: -1 !important;
    pointer-events: none;
  }
  .bottle-float-left.bigline {
    display: none;
  }
  .bigline-container-mobile {
    display: block;
    padding: 0 0 0 0;
    position: relative;
  }
  .bottle-float-left.down-line-winners {
   display: none;
  }
  .bottle-float-left.gps-kalin {
    display: none;
  }
  .bottle-float-left.gps-vlad {
    display: none;
  }
  .bottle-float-left.gps-novo {
    display: none;
  }
  .bottle-float-left.gps-eka {
    display: none;
  }
  .example-all-test {
    margin-bottom: 32vw;
    margin-top: 2.652083vw;
  }

  .bottle-float-left-mobile-gps {
    display: flex;
    position: absolute;
    margin-top: -73.3vw;
    margin-left: 85.83vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon1 {
    display: flex;
    position: absolute;
    margin-top: -10.3vw;
    margin-left: 54.83vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon2 {
    display: flex;
    position: absolute;
    margin-top: 150.7vw;
    margin-left: 16.83vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon3 {
    display: flex;
    position: absolute;
    margin-top: 153.4vw;
    margin-left: 80.53vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon4 {
    display: flex;
    position: absolute;
    margin-top: 170.9vw;
    margin-left: 1.23vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon5 {
    display: flex;
    position: absolute;
    margin-top: 48.9vw;
    margin-left: 77.43vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;

  }
  .bottle-float-left-mobile-gps.bon6 {
    display: flex;
    position: absolute;
    margin-top: 233.9vw;
    margin-left: 9.43vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;
  }
  .bottle-float-left-mobile-gps.bon7 {
    display: flex;
    position: absolute;
    margin-top: 243.9vw;
    margin-left: 77.43vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;
  }
  .bottle-float-left-mobile-gps.bon8 {
    display: flex;
    position: absolute;
    margin-top: 37.9vw;
    margin-left: 16.43vw;
    width: 5.47vw;
    height: auto;
    z-index: 5 !important;
  }
  .bottle-float-left-mobile-gps.bon9 {
    display: flex;
    position: absolute;
    margin-top: 140.9vw;
    margin-left: 86.43vw;
    width: 5.47vw;
    height: auto;
    z-index: 0 !important;
  }
  .absolute-text-mobile {
    z-index: 5;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin: -64vw 21vw 5vw 85vw;
  }
  .absolute-text-mobile.bon1 {
    z-index: 5;
    display: flex;
    color: rgba(0, 98, 65, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin: -1vw 21vw 5vw 43vw;
  }
  .absolute-text-mobile.bon2 {
    z-index: 5;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin: 160vw 21vw 5vw 16vw;
  }
  .absolute-text-mobile.bon3 {
    z-index: 5;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 163.4vw;
    margin-left: 80.53vw;
  }
  .text-block-inputcode {
    display: block;
    margin: 71vw 0vw 0vw -55vw;
  }
  .absolute-text-mobile.bon5 {
    z-index: 5;
    width: 25vw;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 65.4vw;
    margin-left: 92.53vw;
  }
  .absolute-text-mobile.bon6 {
    z-index: 5;
    width: 25vw;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 245.4vw;
    margin-left: 4.53vw;
  }
  .absolute-text-mobile.bon7 {
    z-index: 5;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 253.4vw;
    margin-left: 74.63vw;
  }
  .absolute-text-mobile.bon8 {
    z-index: 5;
    display: flex;
    color: rgba(0, 98, 65, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 47.9vw;
    margin-left: 14.43vw;
  }
  .green-background-full-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    width: 68.75vw;
    height: 120vw;
    border-radius: 2vw;
    background: linear-gradient(to top, rgba(0, 98, 65, 1), rgba(91, 171, 113, 1));
  }
  .block-block {
    display: block;
  }
  .full-image {
    left: 51%;
    height: 35vw;
    width: 55vw;
    position: absolute;
    top: 29%;
    transform: translate(-52%, -50%);
    object-fit: cover;
    object-position: 31% center;
  }
  .text-img-name.number-open {
    position: absolute;
    top: 0%;
    left: 47%;
    transform: translate(-50%, -50%);
    width: 55.5vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-weight: 400;
    font-size: 5.43vw;
    line-height: 5.43vw;
    text-align: center; /* Горизонтальное выравнивание по центру */
    color: white;
    white-space: nowrap;
    text-transform: none;
    float: none !important;
  }
  .container-flex-img {
    display: flex;
    align-items: flex-start; /* Выравнивание по верхнему краю */
  }
  .cards-img.number-open {
    margin: 2.05vw 0vw 0vw 1.15vw;
    position: absolute;
    z-index: 2;
    width: 9.75vw;
    height: auto;
  }
  .text-img-name.down-open {
    position: absolute;
    margin: auto;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55.5vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-weight: 400;
    font-size: 3.74vw;
    line-height: 3.74vw;
    text-transform: none;
    text-align: center; /* Горизонтально по центру */
    color: white;
    white-space: normal;
    float: none !important;
  }
  .text-img-name.down-open-down {
    position: fixed;
    margin: 52.2vw 0vw 0vw 2.5vw;
    width: 63.5vw;
    flex: 1 1;
    font-family: Inter;
    font-weight: 400;
    font-size: 2.0vw;
    line-height: 2.1vw;
    text-align: left;
    color: white;
    white-space: normal;
  }
  .img-bonus-cuva {
    width: 40vw !important;
    margin: -35.1vw 0vw 0vw 7.2vw !important;
    position: absolute;
  }
  .absolute-text-mobile.bon9 {
    z-index: 0;
    display: flex;
    color: rgba(0, 98, 65, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 150.9vw;
    margin-left: 81.43vw;
  }
  #wave-container-test-4 {
    margin-top: 44vw;
    width: 100%;
    height: 5.21vw;
    overflow: hidden;
    background: rgba(0, 98, 65, 1);
  }
  .absolute-text-mobile.bon4 {
    z-index: 5;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Inter";
    font-size: 2.13vw;
    font-weight: 700;
    line-height: 2.08vw;
    position: absolute;
    margin-top: 180.9vw;
    margin-left: 0.23vw;
  }
  bottle-left {
    flex: 1;
    padding: 0 0 0 0;
  }

  .images-moment img {
    margin-left: 3vw;
    margin-right: 0vw;
    width: 48.75vw;
    height: 8.75vw;
    height: auto;
  }
  .slick-slider.prizesShowMoment {
    width: 70.57vw;
    height: 15.17vw;
    margin-left: 8.5vw;
    margin-top: -1vw;
    margin-bottom: -3vw;
  }
  .right-p-prizes-moment {
    font-family: Inter;
    font-weight: 600;
    font-size: 5.87vw;
    line-height: 6.13vw;
    color: white;
  }
  .image-container-moment p {
    margin: 0.19vw 0vw 4vw -9.6vw;
  }
  .prizesShowMoment .slick-prev {
    display: flex !important;
    margin: 13vw 0vw 0vw -10.5vw;
  }
  .prizesShowMoment .slick-next {
    display: flex !important;
    margin: 13vw 0vw -3vw -16.5vw;
  }
  .faq-question-p {
    margin-top: 5vw;
    margin-bottom: 2vw;
    font-size: 5.33vw;
    line-height: 6.40vw;
    font-family: Inter;
    font-weight: 400;

  }
  .faq-question a:hover{
  }

  .scroll-container-winners-block {
    position: relative;
    overflow: hidden;
    width: 94.24vw;
    height: 104vw;
  }
  .faq-question-p-a {
    font-size: 6.67vw;
    line-height: 8vw;
    font-family: Inter;
    font-weight: 400;
    text-decoration: underline;
  }
  .register-inputs-text.new-reg {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-bottom: 5.5vw;
    margin-top: -1vw;
    margin-left: 0vw;
  }
  .slick-slider.center {
    z-index: 2;
    background-color: rgba(245, 245, 237, 0.7);
    width: 43.87vw;
    height: 50.17vw;
    margin-left: 10.5vw;
    margin-top: 15vw;
    border-radius: 6.43vw;
    margin-bottom: 10vw;
  }
  .text-inputcode.cards-prize {
    font-size: 3.47vw;
    margin-top: 1vw;
    margin-left: 2vw;
    margin-bottom: 5vw;
    font-family: AutumnSweaterLaimon;
  }
  .center .slick-prev {
    margin-left: -21.88vw !important;
    margin-top: 5vw;
  }
  .center .slick-next {
    margin-left: -13vw !important;
    margin-right: -11vw;
    margin-top: 5vw !important;
  }
  .slider-cards-winnersall-img {
    width: 18.75vw;
    height: 47.31vw;
    margin: 2vw;
  }
  .cards-img {
    margin: 1vw 0vw 0vw 1vw;
    position: relative;
    z-index: 1;
    width: 36.75vw;
    height: auto;
  }
  .cards-img.number {
    margin: 1.15vw 0vw 0vw 1.15vw;
    position: absolute;
    z-index: 2;
    width: 7.75vw;
    height: auto;
  }
  .text-img-name {
    margin: 1.2vw 0vw 0vw 1.8vw;
    width: 0vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-weight: 400;
    font-size: 4.03vw;
    line-height: 4.96vw;
    color: white;
    float: left;
    white-space: nowrap;
  }
  .text-img-name.down {
    position: absolute;
    margin: 6.2vw 0vw 0vw 2vw;
    width: 31.5vw;
    font-family: Inter;
    font-weight: 400;
    font-size: 2.17vw;
    line-height: 2.16vw;
    text-transform: none;
    text-align: left;
    color: white;
    float: left;
    white-space: normal;
  }
  .cards-img.up {
    margin: 8.05vw 0vw 0vw 33.15vw;
    position: absolute;
    z-index: 2;
    width: 3.75vw;
    height: auto;
  }


  .left.lc {
    display: flex;
    flex-direction: column;
    margin: -163.5vw 0vw 0vw 5.5vw;
  }
  .winners-text.lc {
    z-index: 5;
    width: 50vw;
    margin-top: 8.5vw;
    margin-left: 32.4vw;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 7.47vw;
    line-height: 6.31vw;
    color: #EEF3EC;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
  .for-faq.lc {
    color: rgba(0, 98, 65, 1);
    margin-left: 22.5vw;
    margin-top: 6.4vw;
  }
  .img-bonus {
    width: 43vw !important;
    margin: -32.4vw 0vw 0vw 6.1vw !important;
    position: absolute;
  }
  .back-img.how-prizes-new {
    opacity: 0.75;
    margin: 0vw 3vw 0.2vw 1.35vw !important;
    width: 81% !important;
  }
  .bottle-float-left.exit-lc-text {
    margin: -7vw 0vw 0vw 60vw;
    font-size: 3.39vw;
    line-height: 5.67vw;
    position: absolute;
    width: 6.4vw;
    height: 6.4vw;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .bottle-float-left.exit-lc {
    margin: -13vw 0vw 0vw 37vw;
    position: absolute;
    width: 6.4vw;
    height: 6.4vw;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .left-p-name.lc {
    margin: 4.35vw 0vw -1vw 5.4vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-weight: 400;
    font-size: 5vw;
    line-height: 6.13vw;
    text-transform: uppercase;
    color: rgba(0, 98, 65, 1);
  }
  .shadows-li.shadows-li {
    display: inherit;
  }
  .slider-codes-winnersall {
    padding: 4.8vw 5.5vw 4.79vw 5.8vw;
    margin: 0vw 0vw 0vw 0vw;
    border-radius: 4.04vw;
    color: rgba(0, 98, 65, 1);
    font-family: 'Acrom Medium', Inter, sans-serif;
    font-weight: 500;
    font-size: 4.25vw;
    line-height: 3.53vw;
    background-color: white;
  }
  .slick-slider.winnersSearchCodes {
    width: 48.57vw;
    height: 4.17vw;
    margin-left: 5.5vw;
    margin-top: 3vw;
  }
  .winnersSearchCodes .slick-prev, .winnersSearchCodes .slick-next {
    margin-top: -0.5vw !important;
    margin-left: -14vw;
  }
  .winnersSearchCodes .slick-next {
    margin-right: -8.1vw;
  }
  .right-p-name.lc {
    color: #006241;
    font-family: AutumnSweaterLaimon, Arial, sans-serif;
    font-size: 6vw;
    font-weight: 400;
    line-height: 6vw;
    margin: -56.65vw 0 -3vw -3.8vw;
    text-transform: uppercase;
    width: 73vw;
  }
  input.profile-mobile {
    font-family: Inter;
    width: 57.56vw;
    height: 14.1vw;
    border: 0.14vw solid red;
    border-radius: 6.68vw;
    padding: 0.35vw;
    font-size: 1.11vw;
    margin-top: 5.1vw;
    margin-left: -6.2vw;
    text-align: center;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    z-index: 5;
    position: absolute;
}
  .bottle-float-left.profile-mobile {
    position: absolute;
    margin-top: 5.1vw;
    margin-left: -35.97vw;
    width: 46.81vw;
    height: 14.27vw;
    z-index: 6;
  }
  .text-inputcode.lc {
    color: rgba(0, 98, 65, 1);
    margin: 22vw 0vw 0vw -25vw;
    font-family: Inter;
    font-weight: 400;
    font-size: 3.25vw;
    line-height: 3.18vw;
    text-transform: none;
  }
  .winners-text.lc-text-profile {
    margin-top: -85.8vw;
  }
  .lc-text-profile-d {
    color: #006241;
    font-family: AutumnSweaterLaimon, Arial, sans-serif;
    font-size: 7.47vw;
    font-weight: 400;
    height: 4.31vw;
    line-height: 7.31vw;
    margin: -1.2vw 0 0 0.5vw;
    text-transform: uppercase;
    width: 74.76vw;
  }
  .lc-text-profile-d-down {
    color: #006241;
    font-family: Inter;
    width: 89vw;
    font-size: 4.08vw;
    font-weight: 400;
    height: 7.5vw;
    line-height: 4.5vw;
    margin: 11.8vw 0 10vw 4.6vw;
    text-align: center;
    text-transform: none;
    width: 68.49vw;
  }
  .bottle-float-left.example-tree5.profile {
    z-index: -1;
    margin-top: -35.95vw;
    margin-left: 85.13vw;
    width: 15.19vw;
    height: 15.89vw;
  }
  .text-inputcode.profile-mobile {
    position: absolute;
    width: 62vw;
    margin: -3vw 0vw 0vw 3vw;
    font-family: Inter;
    font-weight: 400;
    font-size: 3.47vw;
    line-height: 3.27vw;
    text-transform: none;
  }
  .text-inputcode.cards-prize {
    font-size: 5.47vw;
    margin-top: 2.5vw;
    margin-left: 2vw;
    margin-bottom: 5vw;
    font-family: AutumnSweaterLaimon;
  }
  .accordion-example.lc {
    padding-top: 0vw;
    position: relative;
    z-index: 1;
    padding-bottom: 9vw;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: inherit;
    width: 100%;
    font-size: 0.83vw;
  }
  .error {
    width: 100%;
    z-index: 5;
    position: relative;
  }
  .custom-checkbox.error {
    position: relative;
    display: inline-block;
    width: 6.2vw;
    height: 6.2vw;
    background-color: #FFFFFF;
    border-radius: 3.44vw;
    margin-left: 0vw;
    margin-top: 0.0vw;
    margin-bottom: 1.3vw;
  }
  .error-clear {
    text-decoration: none;
    color: white;
  }
  .copied-notification {
    color: rgba(0, 98, 65, 1);
    font-family: Inter;
    font-weight: 400;
    font-size: 4.33vw;
    line-height: 5.40vw;
    position: absolute;
    margin: -20vw 0vw 0vw 23vw;
    animation: fade-in-out 1.5s ease-out;
  }
  .error-text {
    color: rgba(0, 98, 65, 1);
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 9.32vw;
    font-weight: 400;
    line-height: 9.04vw;
  }
  .error-text.first {
    margin-top: 25vw;
    margin-bottom: 5vw;
  }
  .error-text.second{

    font-size: 38.67vw;
    font-weight: 400;
    line-height: 25.56vw;
  }
  .error-text.third{
    width: 77.49vw;
    height: 35vw;
    font-family: Inter;
    text-align: center;
    font-size: 4.39vw;
    font-weight: 400;
    line-height: 4.67vw;
    margin: 0 auto;
    margin-top: 9vw;
  }
  .centered-container {
    z-index: 5 !important;
    width: 86% !important;
  }
  .centered-container.jss2 {
    z-index: 5 !important;
    width: 86% !important;
  }
  .green-background {
    padding-top: 0.7vw;
    padding-left: 0.7vw;
    position: relative;
    z-index: 1;
    width: 39.75vw;
    height: 46vw;
    border-radius: 6vw;
    background: linear-gradient(to top, rgba(0, 98, 65, 1), rgba(91, 171, 113, 1));
  }
  .back-img {
    margin: 12vw 0vw 0vw 13.5vw !important;
    width: 188% !important;
  }
  .test-img {
    margin: -66vw 0vw -0.4vw 30.25vw !important;
    position: relative !important;
    width: 76% !important;
  }
  .test-img.pol15 {
    position: relative !important;
    margin: -72vw 0 -6.4vw 28.25vw !important;
    width: 88% !important;
  }
  .test-img.pol10 {
    margin: -71vw 0 -5.4vw 28.7vw !important;
    position: relative !important;
    width: 86% !important;
  }
  .test-img.for-05 {
    margin: -71vw 0vw -3.4vw 28.65vw !important;
    position: relative !important;
    width: 90% !important;
  }
  .test-img.img33 {
    margin: -80vw 0vw -4.2vw -6.75vw !important;
    position: relative !important;
    width: 321% !important;
  }
  .main-promo {
    position: absolute;
    margin: -48vw 0vw 0vw 34.4vw;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 5.825vw;
    font-weight: 400;
    line-height: 5vw;
    color: rgba(227, 29, 26, 1);
    z-index: 5;
  }
  .main-promo-text-input {
    position: absolute;
    margin: 106.97vw 0vw 0vw -1.4vw;
    text-transform: none;
    color: white;
    font-family: Inter, sans-serif;
    font-size: 3.467vw;
    line-height: 3.328vw;
    font-weight: 600;
    z-index: 10;
  }
  .main-promo-text-input-new {
    position: absolute;
    margin: 5.37vw 0vw 0vw -7.7vw;
    text-transform: none;
    color: white;
    font-family: Inter, sans-serif;
    font-size: 3.467vw;
    line-height: 3.328vw;
    font-weight: 600;
    z-index: 142;
  }
  .main-promo-text-input-new-mobile {
    position: absolute;
    margin: 10.65vw 0vw 0vw -3.7vw;
    text-transform: none;
    color: white;
    font-family: Inter, sans-serif;
    font-size: 3.467vw;
    line-height: 3.328vw;
    font-weight: 600;
    z-index: 142;
  }
  .main-promo.block {
    display: none;
  }
  .main-promo-block-test {
    display: block;
    position: absolute;
    border-radius: 2.903vw;
    border: 1px;
    background: rgba(255, 255, 255, 0.31);
    color: rgba(0, 98, 65, 1);
    width: 64.722vw;
    height: 21.564vw;
    font-size: 3.45vw;
    border-color: rgba(0, 98, 65, 1);
    border-style: solid;
    line-height: 3.4vw;
    padding-top: 2.2vw;
    text-transform: none;
    text-align: center;
    margin: -33vw 0vw 0vw 3vw;
    font-family: Inter, sans-serif;
  }
  .bottle-float-left.air {
    position: absolute;
    margin-top: -13.25vw;
    margin-left: -75.17vw;
    width: 21.642vw;
    height: auto;
    z-index: 5;
  }
  .bottle-float-left.air-bonus {
    position: absolute;
    margin-top: 9.65vw;
    margin-left: -88.17vw;
    width: 53.659vw;
    height: auto;
    z-index: 5;
  }
  .popup-content-code.success-reg-new {
    width: 83.697vw !important;
  }
  .vertical-line-reg {
   display: none;
  }
  .register-inputs-text.new-reg-number {
    width: 64vw;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: left;
    margin-bottom: 5.5vw;
    margin-top: -3vw;
    margin-left: 8vw;
  }
  .bottle-float-left.success-image-new {
    padding-right: 0.7vw;
    margin: -17.5vw 0vw 0vw -9.5vw;
    position: absolute;
    width: 16.81vw;
    height: auto;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .success-reg-new-left {
    float: none;
    width: 50vw;
    margin-left: 11vw;
    box-sizing: border-box;
  }
  .success-reg-new-right {
    float: none;
    padding-left: 5px;
    box-sizing: border-box;
    margin-top: -9.5vw;
    text-align: left;
  }
  .register-main-text.new-meow-second {
    width: 69vw;
    margin-top: 15vw;
    margin-left: -3vw;
    text-align: center;
    margin-bottom: 6.4vw;
  }

  .horiz-line-reg {
    position: absolute;
    display: block;
    border-bottom: 2px solid #A3D441;
    width: 84%;
    margin: 0px auto;
    margin-left: -10vw;
  }
  .register-main-text.new-meow-1 {
    position: absolute;
    text-align: left;
    margin: -6.6vw 14vw 0vw -5.4vw;
  }
  .register-main-text.new-meow-2 {
    text-align: left;
    position: absolute;
    margin: -9.6vw 14vw 0vw -5.4vw;
  }
  .container-right {
    display: block;
    margin: 1vw -2.3vw 0vw 2vw;
    padding-top: 1vw;
  }
  .error-message.check-box-error {
    position: absolute;
    margin: 0vw 0vw 0vw 0.2vw;
    font-size: 2.5vw;
    width: 55vw;
  }
  .error-message.check-box-error-second {
    position: absolute;
    margin: -1.2vw 0vw 0vw 0.2vw;
    font-size: 2.5vw;
    width: 55vw;
  }


  .Modal-add-u {
    width: 96.188vw !important;
    height: 57.146vw !important;
  }

  .content-right-u {
    position: absolute;
    margin: -52.4vw 0vw 0vw 57.45vw;
    background-color: #FFC828;
    border-bottom-right-radius: 2.08vw;
    border-top-right-radius: 2.08vw;
    flex: 1 1;
    display: flex;
    width: 37vw;
    height: 57vw;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .register-main-text.u {
    width: 58vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #FFFFFF;
    font-family: 'AutumnSweaterLaimon', Arial, sans-serif;
    font-size: 5.814vw;
    font-weight: 400;
    line-height: 6vw;
    margin-left: -2.4vw;
  }
.register-inputs-mobile {
  display: flex;
  margin-top: 5vw;
  width: 38vw;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 3.5vw;
  margin-left: -2vw;
  font-weight: 600;
  font-size: 2.713vw;
  line-height: 2.922vw;
}
  .lfur-r-linef {
    position: absolute;
    margin: -52.434vw 0vw 0vw 57.2vw;
    z-index: 1;
    width: 3.478vw;
    height: auto;
  }
  .register-inputs-text.u2 {
    display: none;
  }
  .register-inputs-text.u {
    display: none;
  }
  .lfur-r-btl {
    margin: 8vw 0vw 0vw -1.3vw;
    width: 37vw;
    z-index: 3;
    height: auto;
  }
  .lfur-r-tct {
    position: absolute;
    margin: -26.5vw 0vw 0vw 51vw;
    z-index: 2;
    width: 25vw;
    height: auto;
  }
  .bottle-float-left.exit-register-u {
    right: 0;
    padding-right: 1.7vw;
    margin: -15.2vw 0vw 0vw -2vw;
    position: absolute;
    width: 8vw;
    height: auto;
    z-index: 5;
    transition: opacity 0.3s ease;
  }
  .link-here-mobile {
    display: flex;
    background-image: linear-gradient(to right, #FFC828, #E31D1A, #E31D1A);
    margin: -0.7vw 0vw 0vw -2.3vw;
    padding: 1.2vw 1.8vw 1.2vw 1.8vw;
    border-radius: 2vw;
    position: absolute;
    cursor: pointer;
    font-family: Inter;
    font-weight: 600;
    font-size: 2.18vw;
    line-height: 2.46vw;
    text-decoration: none;
    color: #FFFFFF;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  .link-here {
   display: none;
  }
  .bottle-float-left.u {
   display: none;
  }
  .lfur-r {
    margin: -6vw 0 0 -60vw;
    opacity: .7;
    width: 97vw;
  }
}